/** The keys from this object must be the same as the paths used in
 * the nested routes. Check the CampaignCreation file.
 */
export const TemplateTabName = {
	'video-page': 'Video Page*',
	widget: 'Widget*',
	email: 'Email',
	sms: 'SMS',
	whatsapp: 'WhatsApp',
};

export enum TemplateHeader {
	title = 'Customize your campaign',
	text = 'Make them your own and improve conversion rates at each step.',
	collectVideosText = 'Add a personal touch before launching.',
	required = '* required',
}

export const TemplateValidatorMessages = {
	templateNameRequired: 'Template name is required.',
	primaryColorRequired: 'Buttons color is required.',
	primaryColorInvalid:
		'The buttons color does not exist. Please select a valid hexadecimal color.',
	secondaryColorRequired: 'Background color is required.',
	secondaryColorInvalid:
		'The background color does not exist. Please select a valid hexadecimal color.',
};

export const TemplateCampaignValidatorMessages = {
	replyToAddressRequired: 'Email "Reply to email address" is required.',
	replyToNameRequired: 'Email "Reply to display name" is required.',
	emailSubjectTitleRequired: 'Email Subject is required.',
	smsBodyRequired: 'SMS Body is required.',
	replyToAddressInvalid:
		'Email "Reply to email address" is not a valid email address.',
	widgetDomainRequired: 'Site is required.',
	widgetPathRequired: 'Route is required.',
	widgetPathInvalid:
		'Route is invalid, must start with "/", spaces are not allowed. e.g. "/home".',
	emailSenderNameRequired: 'Sender name is required.',
	emailSendingAddressRequired: 'Sending address is required.',
	emailSendingDomainRequired: 'Sending domain is required.',
};

export const CTAValidatorMessages = {
	internalActionNameRequired: 'Internal action name is required.',
	internalRepliesNameRequired: 'Internal replies name is required.',
	internalRepliesNameMax: 'Internal replies name is too long, max of',
	primaryLinkTitleRequired: 'Primary button title is required.',
	primaryLinkUrlRequired: 'Primary button URL is required.',
	primaryLinkUrlInvalid: 'Primary button URL is not a valid URL.',
	secondaryLinkTitleRequired: 'Secondary button title is required.',
	secondaryLinkUrlRequired: 'Secondary button URL is required.',
	secondaryLinkUrlInvalid: 'Secondary button URL is not a valid URL.',
	buttonTitleRequired: 'Button title is required.',
	eventTitleRequired: 'Event title is required.',
	eventDateRequired: 'Event date is required.',
	eventDateInvalid: 'Event date is not a valid date, must be in the future.',
	linkUrlRequired: 'Button URL is required.',
	linkUrlInvalid: 'Please ensure URL is valid including starting with https://',
};

export enum TemplateProviderStates {
	DEFAULT = 'default',
	NEW = 'new',
	SAVED = 'saved',
}

export enum TemplateProviderFieldsSetter {
	TEMPLATE = 'template',
	OVERLAY = 'overlay',
	PLACEMENT = 'placement',
	SMS = 'sms',
	EMAIL = 'email',
	SETTINGS = 'settings',
}

export enum TemplateProviderFields {
	HEADER_TEXT = 'headerText',
	BODY_TEXT = 'bodyText',
	PLAY_BUTTON_TEXT = 'playButtonText',
	END_HEADER_TEXT = 'endHeaderText',
	BORDER_RADIUS = 'borderRadius',
	NAME = 'name',
	POSITION = 'position',
	PRIMARY_COLOR = 'primaryColor',
	BACKGROUND_IMAGE = 'backgroundImage',
	SNAPCHAT_BACKGROUND_IMAGE = 'snapchatBackgroundImage',
	LOGO = 'logo',
}

export enum WidgetTemplatePositionEnum {
	BOTTOM_LEFT = 'bottom-left',
	BOTTOM_RIGHT = 'bottom-right',
}

export enum TemplateFieldsValidatorTypeEnum {
	ALL_VIDEO_PAGE = 'all-video-page',
	ALL_WIDGET = 'all-widget',
	EMAIL = 'email',
	SMS = 'sms',
	WIDGET = 'widget',
}

export const TemplateGenericTextValues = {
	headerText: '{{{storyteller.firstName}}} has a message for you',
	playButtonText: 'Watch video',
	endHeaderText: 'Thanks for watching.',
};

export const TabAlerts = {
	KEEP_EMAIL_SMS_DEFAULT:
		'Do you want to continue using this workspace’s default email and SMS templates?',
	KEEP_EMAIL_DEFAULT:
		'Do you want to continue using this workspace’s default email template?',
	KEEP_SMS_DEFAULT:
		'Do you want to continue using this workspace’s default SMS template?',
};

export enum ScreenDimensionsEnum {
	MOBILE = 'mobile',
	DESKTOP = 'desktop',
}

export const ACCORDION_NAME = {
	LOGO: 'Logo',
	VIDEO_TEXT: 'Video page text',
	WIDGET_TEMPLATE: 'Widget template',
	CAMPAIGN_PLACEMENT: 'Campaign placement*',
	BACKGROUND: 'Background',
	LOGO_BACKGROUND: 'Logo & background',
	VIDEO_BACKGROUND: 'Video Background',
	COLORS_BORDERS: 'Colors & borders',
	OVERLAYS: 'Overlays ✨',
};

export const LABELS = {
	headerText: 'Preview title',
	headerText2: 'Play video message',
	bodyText: 'Preview description',
	playButtonText: 'Preview button text',
	playButtonText2: 'Play video button text',
	endHeaderText: 'End message',
	site: 'Site',
	route: 'Route',
};

export const TEXT = {
	CONTENT: {
		PLAY_STATUS: 'Play',
		PREVIEW_STATUS: 'Preview',
		END_STATUS: 'End',
		POSITION: 'Position',
		RESPONSIVENESS: 'Responsiveness',
		NEXT_STATE: 'Set up next state',
		CONTENT_DESCRIPTION:
			"Customize the video's look before, during and after play.",
		OVERLAYS_DESCRIPTION:
			'Add an Overlay to your videos to create a consistent brand look and to add visual flair.',
		PLACEMENT_DESCRIPTION:
			'Set the website, route and positioning where this campaign will show. You can add websites domains',
		PLACEMENT_ROUTE_DESCRIPTION: 'e.g: /home, /my-cool-path',
	},
	CTA: 'Select a call to action*',
	TEMPLATE_HEADING: 'Select a template',
	COLORS: {
		BUTTONS: 'Buttons',
		BACKGROUND: 'Background',
	},
	ALERT: {
		TEMPLATE_EDITED:
			'Editing this template will also update it for ongoing campaigns with the same template.',
		CTA_MISSING: 'Add a Call to action to continue.',
		DEFAULT_SELECTED:
			'To update the default template, the video page configuration needs to be updated directly on the workspace settings.',
	},
};

export const placeholderVideoIds = {
	QA: 'O7D1hCPy',
	PROD: '6p4gbGMs',
	DEV: 'rRjy6Yyt',
	LOCAL: 'ki1vICTV',
};
