import React, { useState } from 'react';
import {
	Avatar,
	Divider,
	Label,
	Text,
} from '@common/design-system/components/atoms';

import * as S from './PopupMenu.styles';
import { useNavigate } from 'react-router-dom';
import useWorkspaces from '@app/hooks/useWorkspaces';
import { PageRouteEnum } from '@app/constants/pages';
import { useSelector } from 'react-redux';
import { RootState } from '@app/redux/types';
import useFloatingUi from '@app/hooks/useFloatingUi';
import useDebounce from '@app/hooks/useDebounce';
import GoodkindDSTheme from '@common/design-system/global/theme';
import {
	checkWorkspaceHasReelsPlan,
	checkWorkspaceHasSMSPlan,
	checkWorkspaceHasVideoMessagingPlan,
} from '@app/utils/modules/workspace';
import { APP_VERSION, ToastMessage, webRecordingUrl } from '@app/constants';
import NavOption from '../NavOption/NavOption';
import WorkspaceButton from '../WorkspaceButton/WorkspaceButton';
import WorkspaceSelector from '../WorkspaceSelector/WorkspaceSelector';
import LocalStorage from '@app/utils/LocalStorage';
import useDatadog from '@app/hooks/useDatadog';
import authConfig from '@app/services/Auth/authConfig';
import { copyToClipboard } from '@app/utils/copyToClipboard';
import { IconWeight } from 'phosphor-react';
import {
	checkIsWorkspaceAdmin,
	getStorytellerName,
} from '@app/utils/modules/storytellers';
import { SettingsRoute } from '@app/containers/SettingsV2/constants';
import { useAuth } from '@app/services/Auth/useAuth';
import { FloatingPortal } from '@floating-ui/react';

export type PopupMenuProps = {
	trigger: (isPopupMenuOpen: boolean) => React.ReactNode;
};

export default function PopupMenu({ trigger }: PopupMenuProps) {
	const navigate = useNavigate();
	const { unsetDatadogUser } = useDatadog();
	const { authProvider } = useAuth();

	const user = useSelector(({ auth: { user } }: RootState) => user);

	const { workspaceInfo, loading } = useSelector(
		({ workspace: { workspaceInfo, loading } }: RootState) => ({
			workspaceInfo,
			loading,
		}),
	);
	const [searchValue, setSearchValue] = useState('');
	const debouncedSearch = useDebounce(searchValue, 500);

	const {
		workspaces,
		workspacesCount,
		loading: getWorkspacesLoading,
	} = useWorkspaces(debouncedSearch);

	const belongsToOnlyOneWorkspace = workspacesCount === 1;

	const isWorkspaceAdmin = checkIsWorkspaceAdmin(user);
	const hasSMSPlan = checkWorkspaceHasSMSPlan(workspaceInfo);
	const hasReelsPlan = checkWorkspaceHasReelsPlan(workspaceInfo);
	const hasVideoMessagingPlan =
		checkWorkspaceHasVideoMessagingPlan(workspaceInfo);

	const workspaceSelectorFloatingProps = useFloatingUi({
		preferredPlacement: 'right-end',
		offset: 12,
	});

	const popupMenuFloatingProps = useFloatingUi({
		preferredPlacement: 'right-end',
		offset: 4,

		onMenuClose: () => {
			workspaceSelectorFloatingProps?.setIsOpen(false);
		},
	});

	function handleSignOut() {
		LocalStorage.clear();

		unsetDatadogUser();

		return authProvider?.logout({ returnTo: authConfig.logoutUrl } as any);
	}

	function handleCopyWorkspaceId(e: React.MouseEvent) {
		e.stopPropagation();

		const workspaceId = workspaceInfo?._id;

		if (!workspaceId) return;

		copyToClipboard(workspaceInfo._id, ToastMessage.workspaceIdCopied);
	}

	const NavOptions = [
		...(isWorkspaceAdmin && hasSMSPlan
			? [
					{
						text: 'Teams',
						icon: 'audience',
						iconWeight: 'fill' as IconWeight,
						redirectTo: `/${PageRouteEnum.teams}`,
					},
				]
			: []),
		{
			icon: 'gear',
			text: 'Settings',
			redirectTo: `/${PageRouteEnum.settings}`,
		},
		...(hasReelsPlan || hasVideoMessagingPlan
			? [
					{
						icon: 'record',
						text: 'Web recorder',
						redirectTo: `${webRecordingUrl}?workspaceId=${workspaceInfo?.id}`,
					},
				]
			: []),
		{
			icon: 'file-text',
			text: 'Terms and conditions',
			redirectTo: `/${PageRouteEnum.settings}/${SettingsRoute.termsConditions}`,
		},
	];

	const handleWorkspaceLogin = (workspaceId: string, email: string) => {
		popupMenuFloatingProps?.setIsOpen(false);

		if (workspaceId === workspaceInfo._id) return;

		navigate(`/${PageRouteEnum.changingWorkspace}`, {
			state: { workspace: { id: workspaceId, email: email } },
		});
	};

	const workspacesOptions = workspaces.map((workspace: any) => {
		return {
			text: workspace.title,
			image: workspace.logo,
			type: workspace._id === workspaceInfo._id ? 'primary' : 'regular',
			active: workspace?._id === workspaceInfo?._id,
			onClick: () => {
				handleWorkspaceLogin(workspace.id, workspace.email);
			},
		};
	});

	const popupMenuHeight =
		popupMenuFloatingProps?.internalRefs?.floating?.current?.clientHeight ?? 0;

	return (
		<>
			<div>
				<div
					ref={popupMenuFloatingProps?.openingReference}
					role="button"
					tabIndex={0}
					{...popupMenuFloatingProps?.getReferenceProps()}
				>
					{trigger(popupMenuFloatingProps?.isOpen)}
				</div>
				{popupMenuFloatingProps?.isOpen && (
					<FloatingPortal>
						<div
							ref={popupMenuFloatingProps?.floatingReference}
							style={{
								position: popupMenuFloatingProps?.strategy,
								top: popupMenuFloatingProps?.y ?? 0,
								left: popupMenuFloatingProps?.x ?? 0,
								zIndex: GoodkindDSTheme.zIndex.fixed,
							}}
							{...popupMenuFloatingProps?.getFloatingProps()}
						>
							{workspaceSelectorFloatingProps?.isOpen && (
								<WorkspaceSelector
									floatingConfig={workspaceSelectorFloatingProps}
									isLoading={getWorkspacesLoading}
									isDisabled={belongsToOnlyOneWorkspace}
									searchValue={searchValue}
									handleSearchValue={setSearchValue}
									popupMenuHeight={popupMenuHeight}
									workspacesOptions={workspacesOptions}
								/>
							)}

							<S.MenuWrapper>
								<S.UserInfoContainer>
									<S.UserInfoWrapper>
										<Avatar
											size="medium"
											name={getStorytellerName(user)}
											image={user?.profilePicture}
										/>
										<S.UserInfoDetails>
											<Label size="small" mediumBold lineHeight="regular">
												{getStorytellerName(user)}
											</Label>
											<Text
												size="xs"
												color="system.text.medium"
												fontWeight="regular"
												lineHeight="regular"
											>
												{user?.email}
											</Text>
										</S.UserInfoDetails>
									</S.UserInfoWrapper>
								</S.UserInfoContainer>
								<Divider />
								<S.NavListContainer>
									{NavOptions.map((option, index) => (
										<NavOption
											key={`workspace-nav-option-${index}`}
											icon={option.icon}
											text={option.text}
											redirectTo={option.redirectTo}
											setPopupMenuIsOpen={popupMenuFloatingProps?.setIsOpen}
										/>
									))}
								</S.NavListContainer>

								<Divider />

								<S.BottomContainer>
									<span
										className="w-100"
										ref={workspaceSelectorFloatingProps?.openingReference} // This is to make the workspace selector use the popup menu as a close reference for the height matching
										{...workspaceSelectorFloatingProps?.getReferenceProps()}
									>
										<WorkspaceButton
											focused={workspaceSelectorFloatingProps?.isOpen}
											canSwitchWorkspace={!belongsToOnlyOneWorkspace}
											workspaceName={workspaceInfo?.organizationName}
										/>
									</span>

									<NavOption
										icon="door-open"
										onClick={handleSignOut}
										text="Sign out"
										textColor="error.text.default"
										iconColor="error.icon.default"
										setPopupMenuIsOpen={popupMenuFloatingProps?.setIsOpen}
									/>
									<S.VersonContainer onClick={handleCopyWorkspaceId}>
										<Text
											size="xxs"
											color="system.text.weak"
											fontWeight="regular"
											lineHeight="regular"
											letterSpacing="large"
										>
											Goodkind v{APP_VERSION}
										</Text>
									</S.VersonContainer>
								</S.BottomContainer>
							</S.MenuWrapper>
						</div>
					</FloatingPortal>
				)}
			</div>
		</>
	);
}
