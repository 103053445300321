const convertAssetToBase64 = async (assetUrl: string) => {
	const response = await fetch(assetUrl);
	if (!response.ok) throw new Error('Failed to fetch asset');
	const blob = await response.blob();

	const base64data = await new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});

	return base64data;
};

export default convertAssetToBase64;
