import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import { normalize } from 'normalizr';
import {
	getAssignedStoriesTypes,
	editStoriesTypes,
	bulkDeleteTypes,
	getSingleStoryTypes,
} from './action';
import entityNormalizer from '../../utils/entityNormalizer';

const story = entityNormalizer('stories', {}, { excludeTime: true });

const pagination = { stories: [story] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState = {
	loading: false,
	stories: {},
	singleStory: {},
	storiesCount: 0,
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {
		getSingleStoryLoading: false,
		getAssignedStoriesLoading: false,
		reload: false,
	},
};

export type storiesStateType = typeof initialState;

function assignedStoriesPaginationMapper(
	state: storiesStateType,
	action: ResponseAction,
	cb: (stories: any, state: storiesStateType) => void,
) {
	const {
		response: {
			stories,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;

	const normalizedData = normalize(
		{ stories, page, pageSize, totalPages, count },
		pagination,
	);
	if (state.pagination) {
		state.pagination.pages[action.response.page] =
			normalizedData.result.stories;
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count =
			Object.keys(normalizedData.entities.stories || {}).length *
			action.response.page;
	}

	state.storiesCount = action.response.count;
	cb(normalizedData.entities.stories, state);
}

function paginationMapper(stories: any, state: storiesStateType) {
	state.stories = { ...stories };
}

function filterMapper(stories: any, state: storiesStateType) {
	if (state.pagination && state.pagination.currentPage === 1) {
		return paginationMapper(stories, state);
	}
	state.stories = { ...state.stories, ...stories };

	if (state.custom) {
		state.custom.reload = false;
	}
}

function bulkActionSuccessMapper(
	state: storiesStateType,
	action: ResponseAction,
) {
	const {
		response: { stories },
	} = action;
	stories.map((story: string) => {
		const { [story]: omittedStory, ...storiesRest } = state.stories;
		state.stories = storiesRest;
	});

	if (state.custom) {
		state.custom.reload = true;
	}
}

function editActionSuccessMapper(
	state: storiesStateType,
	action: ResponseAction,
) {
	const storiesId = action.payload.EditOptions.storiesId;
	const storyteller = action.payload.storyteller;
	const storytellerFilter = action.payload.storytellerFilter;
	if (storytellerFilter && storytellerFilter !== storyteller.id) {
		state.stories = Object.keys(state.stories).reduce(
			(newObject: { [key: string]: any }, storyId: string) => {
				if (!storiesId.includes(storyId)) {
					newObject[storyId] = { ...state.stories[storyId] };
				}
				return newObject;
			},
			{},
		);
	} else {
		storiesId.map((storyId: string) => {
			state.stories[storyId] = {
				...state.stories[storyId],
				assignedTo: storyteller,
			};
		});
		state.stories = { ...state.stories };
	}
}

function getSingleStoryMapper(state: storiesStateType, action: ResponseAction) {
	const newStory = action.response.story;

	if (newStory?.assignedTo && !newStory.assignedTo.fullName) {
		newStory.assignedTo.fullName = `${newStory.assignedTo.firstName} ${newStory.assignedTo.lastName}`;
	}

	state.singleStory = newStory;
}

const assignedSlice = createSlice({
	name: 'assigned',
	initialState,
	reducers: {
		CLEAR_SINGLE_STORY(state: storiesStateType) {
			state.singleStory = null;
			state.custom = {
				...state.custom,
				getSingleStoryLoading: null,
				getSingleStoryResponse: null,
			};
		},
	},
	extraReducers: {
		...createFetchReducer(
			getAssignedStoriesTypes,
			(state: storiesStateType, action) =>
				assignedStoriesPaginationMapper(state, action, filterMapper),
		),
		...createFetchReducer(editStoriesTypes, editActionSuccessMapper),
		...createFetchReducer(bulkDeleteTypes, bulkActionSuccessMapper),
		...createFetchReducer(getSingleStoryTypes, getSingleStoryMapper),
	},
});

export default assignedSlice.reducer;
