import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const OptionWrapper = styled(Link)<{
	focused?: boolean;
	disabled?: boolean;
}>`
	width: 100%;
	height: 44px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0px 10px;
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	background: ${({ focused, disabled, theme }) =>
		focused && !disabled && theme.colors.system.background.weak};
	cursor: ${({ disabled }) => !disabled && 'pointer'};

	&:hover {
		background: ${({ disabled, theme }) =>
			!disabled && theme.colors.system.background.weak};
	}
`;

export const NavOptions = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.xs};
	align-self: stretch;
	border-radius: ${({ theme }) => theme.shapeRadius.xs};

	width: 100%;
`;

export const OptionDetails = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	width: 100%;

	.text-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing.xs};
	}
`;
