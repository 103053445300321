import React from 'react';

import * as S from './Toggle.styles';
import { Label } from '@common/design-system/components/atoms';

export type TogglePropsType = {
	toggle: boolean;
	disabled?: boolean;
	ariaLabel: string;
	label?: string;
	labelInfo?: string;
	onClick: (toggle: boolean, e: React.MouseEvent) => void;
};

export default function Toggle({
	toggle,
	disabled = false,
	onClick,
	ariaLabel,
	label,
	labelInfo,
}: TogglePropsType) {
	return (
		<S.ToggleWrapper>
			<S.Toggle
				type="button"
				tabIndex={0}
				aria-pressed={toggle}
				aria-label={ariaLabel}
				toggle={toggle}
				disabled={disabled}
				onClick={(e) => {
					e.stopPropagation();
					if (!disabled) onClick(!toggle, e);
				}}
			>
				{/* Toggle Circle */}
				<S.ToggleCircle toggle={toggle} disabled={disabled} />
			</S.Toggle>
			{label && (
				<Label
					size="small"
					tooltipInfo={labelInfo}
					onClick={(e: React.MouseEvent) => {
						e.stopPropagation();
						if (!disabled) onClick(!toggle, e);
					}}
				>
					{label}
				</Label>
			)}
		</S.ToggleWrapper>
	);
}
