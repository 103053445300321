import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import { normalize, schema } from 'normalizr';
import {
	addTrustScoreDocTypes,
	connectHotglueFlowTypes,
	createCustomAttributeTypes,
	createOverrideTypes,
	createWorkspaceDataExportTypes,
	deleteOverrideTypes,
	deleteTrustScoreDocTypes,
	disconnectHotglueIntegrationTypes,
	disconnectWorkspaceIntegrationTypes,
	getCaptionOverrideTypes,
	getExportableFieldsTypes,
	getSendDataOutTargetFieldsTypes,
	getWorkspaceByEmailTypes,
	pinCustomAttributeTypes,
	sendMMSThumbnailPreviewTypes,
	setZapierIntegrationTypes,
	updateCustomAttributesTypes,
	updateHotglueCommunicationPreferenceTypes,
	updateHotglueSyncingScheduleTypes,
	updateMMSOverlayTypes,
	updateTrustScoreDocTypes,
	updateVideoPageSettingsTypes,
	updateWorkspaceIntegrationTypes,
	updateWorkspaceTypes,
	uploadLogoTypes,
	verifyDomainTypes,
	verifyWorkspaceDomainDataTypes,
	WorkspaceInfoTypes,
	zapierKeyTypes,
	syncExportJobTypes,
	editWorkspaceContactExportTypes,
	patchWorkspaceWhatsappBusinessIdTypes,
} from './action';
import entityNormalizer from '../../utils/entityNormalizer';
import { customAttributesSchema } from './schemas';
import { deleteAutomationTypes } from '../automations/action';
import { ContactImportIntegrationNameEnum } from '@app/constants/modules/workspace';
import isSuccessHttpStatus from '@app/utils/isSuccessHttpStatus';
import { showToast } from '@common/design-system/components/molecules';
import { ToastMessage } from '@app/constants';

const workspace = entityNormalizer('workspaces');
const pagination = { workspaces: [workspace] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState = {
	loading: false,
	workspaces: {},
	pagination: {
		pages: {},
		currentPage: null,
	},
	domainData: {},
	zapierKey: '',
	workspaceInfo: {},
	firstLogin: false,
	goodkindAttribute: [],
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {},
};

export type workspaceStateType = typeof initialState;

function paginationMapper(state: workspaceStateType, action: ResponseAction) {
	const {
		response: { workspaces, page = null, pageSize = null, firstLogin },
	} = action;
	const normalizedData = normalize({ workspaces, page, pageSize }, pagination);

	state.workspaces = {
		...state.workspaces,
		...normalizedData.entities.workspaces,
	};
	state.firstLogin = firstLogin || false;
	// state.pagination.pages[action.response.page] = normalizedData.result;
	// state.pagination.currentPage = action.response.page;
}

function zapierGatewayMapper(state: any, action: ResponseAction) {
	const { response } = action;
	if (response && isSuccessHttpStatus(response.status)) {
		state.zapierKey = response.workspace.integrationId;
	}
}

function workspaceInfoMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const { response } = action;

	state.workspaceInfo = response.workspace;

	const workspaceCustomAttributes = response.workspace.customAttributes;

	if (workspaceCustomAttributes?.length) {
		const customAttributeSchema = new schema.Array(customAttributesSchema);

		const normalizedCustomAttributes = normalize(
			workspaceCustomAttributes,
			customAttributeSchema,
		);

		state.normalizedCustomAttributes =
			normalizedCustomAttributes.entities.customAttributes || {};
	}

	const hasBlackbaudIntegration = response.workspace.integrations.findIndex(
		(integration: { [key: string]: any }) =>
			/blackbaud/i.test(integration.integrationName) &&
			integration.integrationOn,
	);
	const hasSalesforceIntegration = response.workspace.integrations.findIndex(
		(integration: { [key: string]: any }) =>
			integration.integrationName ===
				ContactImportIntegrationNameEnum.SALESFORCE &&
			integration.integrationOn,
	);

	if (hasSalesforceIntegration > -1) {
		state.workspaceInfo.hasSalesforceIntegration = true;
	} else {
		state.workspaceInfo.hasSalesforceIntegration = false;
	}
	if (hasBlackbaudIntegration > -1) {
		state.workspaceInfo.hasBlackbaudIntegration = true;
	} else {
		state.workspaceInfo.hasBlackbaudIntegration = false;
	}
}

function uploadLogoMapper(state: workspaceStateType, action: ResponseAction) {
	const { response } = action;
	state.workspaceInfo.logo = response.workspace.logo;
}

function setDomainMapperSucess(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const { response } = action;

	state.workspaceInfo.domainVerified = response.domainVerified;
}

function verifySuccessMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const { response } = action;
	Object.keys(state.domainData).map((domain) => {
		if (Object.keys(response.dnsRecords).includes(domain)) {
			state.domainData[domain].valid = response.dnsRecords[domain].valid;
		}
	});
}

function createCustomAttributeErrorMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const errorCode = action?.error?.response?.data?.errorCode;
	const isDuplicateAttribute = errorCode === 500;

	if (isDuplicateAttribute) {
		return showToast({
			message: ToastMessage.duplicatedCustomAttribute,
			type: 'error',
		});
	} else {
		return showToast({
			message: ToastMessage.errorCreatingCustomAttribute,
			type: 'error',
		});
	}
}

function createCustomAttributeMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const {
		response: { customAttribute },
	} = action;
	const customAttributes = state.workspaceInfo.customAttributes || [];
	if (customAttribute) {
		customAttributes.push(customAttribute);

		const customAttributeSchema = new schema.Array(customAttributesSchema);
		const normalizedCustomAttributes = normalize(
			customAttributes,
			customAttributeSchema,
		);
		state.normalizedCustomAttributes =
			normalizedCustomAttributes.entities.customAttributes || {};
	}
}

function pinCustomAttributeMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const {
		response: { customAttribute },
		payload: { customAttributeIndex },
	} = action;
	const customAttributes = state.workspaceInfo.customAttributes || [];

	if (customAttribute) {
		customAttributes[customAttributeIndex] = customAttribute;
		const customAttributeSchema = new schema.Array(customAttributesSchema);
		const normalizedCustomAttributes = normalize(
			customAttributes,
			customAttributeSchema,
		);
		state.normalizedCustomAttributes =
			normalizedCustomAttributes.entities.customAttributes || {};
	}
}

function updateCustomAttributesSuccess(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const {
		response: { updatedCustomAttribute },
		payload: { attributeIndex },
	} = action;

	const workspaceCustomAttributes = state.workspaceInfo.customAttributes || [];

	if (updatedCustomAttribute) {
		workspaceCustomAttributes[attributeIndex] = updatedCustomAttribute;
		const customAttributeSchema = new schema.Array(customAttributesSchema);

		const normalizedCustomAttributes = normalize(
			workspaceCustomAttributes,
			customAttributeSchema,
		);

		state.normalizedCustomAttributes =
			normalizedCustomAttributes.entities.customAttributes || {};
	}
}

function getOverridesMapper(state: workspaceStateType, action: ResponseAction) {
	const {
		response: { captionOverrides },
	} = action;
	state.workspaceInfo.captionOverrides = captionOverrides;
}

function createOverridesMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const {
		response: { captionOverride },
	} = action;
	const copyOverrides = [...state.workspaceInfo.captionOverrides];
	copyOverrides.push(captionOverride);
	state.workspaceInfo.captionOverrides = copyOverrides;
}

function deleteOverridesMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const {
		payload: { captionOverridesId },
	} = action;
	const copyOverrides = [...state.workspaceInfo.captionOverrides];
	state.workspaceInfo.captionOverrides = copyOverrides.filter(
		({ _id }) => _id !== captionOverridesId,
	);
}

function getExportableFieldsMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const {
		response: { exportableFields },
	} = action;
	state.goodkindAttribute = exportableFields;
}

function createWorkspaceDataExport(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const { response } = action;
	state.workspaceInfo.dataExportSettings =
		response.workspace.dataExportSettings;
}

function editWorkspaceContactExportMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const { response } = action;
	state.workspaceInfo.contactExportSettings =
		response.workspace.contactExportSettings;
}

function addTrustScoreDocMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const { response } = action;

	state.workspaceInfo.trustScoreDocs = response.workspace.trustScoreDocs;
}

function editTrustScoreDocMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const { response } = action;

	state.workspaceInfo.trustScoreDocs = response.workspace.trustScoreDocs;
}

function deleteTrustScoreDocMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const { response } = action;

	state.workspaceInfo.trustScoreDocs = response.workspace.trustScoreDocs;
}

function sendMMSThumbnailPreviewMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const { response } = action;

	console.log({ response });
}

const workspaceSlice = createSlice({
	name: 'workspace',
	initialState,
	reducers: {
		RESET_WORKSPACE(state: workspaceStateType) {
			state.workspaceInfo = {};
		},
	},
	extraReducers: {
		...createFetchReducer(getWorkspaceByEmailTypes, paginationMapper),
		...createFetchReducer(WorkspaceInfoTypes, workspaceInfoMapper),
		...createFetchReducer(connectHotglueFlowTypes, workspaceInfoMapper),

		...createFetchReducer(
			updateWorkspaceIntegrationTypes,
			(state: workspaceStateType, action: ResponseAction) => {
				workspaceInfoMapper(state, action);
			},
		),
		...createFetchReducer(
			updateHotglueCommunicationPreferenceTypes,
			(state: workspaceStateType, action: ResponseAction) => {
				workspaceInfoMapper(state, action);
			},
		),
		...createFetchReducer(
			updateHotglueSyncingScheduleTypes,
			(state: workspaceStateType, action: ResponseAction) => {
				workspaceInfoMapper(state, action);
			},
		),
		...createFetchReducer(
			disconnectWorkspaceIntegrationTypes,
			(state: workspaceStateType, action: ResponseAction) => {
				workspaceInfoMapper(state, action);
			},
		),
		...createFetchReducer(updateWorkspaceTypes, workspaceInfoMapper),
		...createFetchReducer(uploadLogoTypes, uploadLogoMapper),
		...createFetchReducer(updateVideoPageSettingsTypes, workspaceInfoMapper),
		...createFetchReducer(
			verifyWorkspaceDomainDataTypes,
			setDomainMapperSucess,
		),
		...createFetchReducer(verifyDomainTypes, verifySuccessMapper),
		...createFetchReducer(zapierKeyTypes, zapierGatewayMapper),
		...createFetchReducer(
			setZapierIntegrationTypes,
			(state: workspaceStateType, action: ResponseAction) => {
				workspaceInfoMapper(state, action);
			},
		),
		...createFetchReducer(
			disconnectHotglueIntegrationTypes,
			workspaceInfoMapper,
		),
		...createFetchReducer(
			updateCustomAttributesTypes,
			updateCustomAttributesSuccess,
		),
		...createFetchReducer(getSendDataOutTargetFieldsTypes),
		...createFetchReducer(
			createCustomAttributeTypes,
			createCustomAttributeMapper,
			createCustomAttributeErrorMapper,
		),
		...createFetchReducer(pinCustomAttributeTypes, pinCustomAttributeMapper),
		...createFetchReducer(getCaptionOverrideTypes, getOverridesMapper),
		...createFetchReducer(createOverrideTypes, createOverridesMapper),
		...createFetchReducer(deleteOverrideTypes, deleteOverridesMapper),
		...createFetchReducer(
			createWorkspaceDataExportTypes,
			createWorkspaceDataExport,
		),
		...createFetchReducer(
			editWorkspaceContactExportTypes,
			editWorkspaceContactExportMapper,
		),
		...createFetchReducer(getExportableFieldsTypes, getExportableFieldsMapper),
		...createFetchReducer(addTrustScoreDocTypes, addTrustScoreDocMapper),
		...createFetchReducer(updateTrustScoreDocTypes, editTrustScoreDocMapper),
		...createFetchReducer(deleteTrustScoreDocTypes, deleteTrustScoreDocMapper),
		...createFetchReducer(updateMMSOverlayTypes, workspaceInfoMapper),
		...createFetchReducer(
			sendMMSThumbnailPreviewTypes,
			sendMMSThumbnailPreviewMapper,
		),
		...createFetchReducer(
			patchWorkspaceWhatsappBusinessIdTypes,
			workspaceInfoMapper,
		),
		...createFetchReducer(syncExportJobTypes),

		[deleteAutomationTypes[1]](state) {
			if (state?.custom?.disconnectHotglueResponse) {
				state.custom.disconnectHotglueResponse = null;
			}
		},
	},
});

export default workspaceSlice.reducer;
export const WorkspaceActions = workspaceSlice.actions;
