import { orderTypes } from '@app/constants';

export const CampaignIdCopied = 'Campaign ID copied';

export const PAGE_SIZE = 25;

export const defaultOrder = {
	field: 'createdAt',
	type: orderTypes.descending,
};

export enum CampaignActionRequiredDisplayName {
	WAITING_VIDEO_SUBMISSIONS = 'Waiting for videos',
	APPROVE_VIDEOS = 'Pending approvals',
	APPROVE_NEW_CONTACTS = 'Contact addition limit hit',
	SET_AS_RUNNING = 'Set as Running',
	NONE = 'No actions required',
	COMPLETE = 'Complete',
}

export const ContactLimitTrigger = 'Contact limit triggered';
export const RunningCampaign = 'Running campaign';

export enum FilterTab {
	CAMPAIGN_RUNNING = 'campaign_launched',
	CAMPAIGN_DRAFT = 'campaign_draft',
	CAMPAIGN_ARCHIVED = 'campaign_archived',
	ACTIVITY_SCHEDULED = 'activity_scheduled',
	ACTIVITY_SENT = 'activity_sent',
}

export const SETASRUNNINGINFO =
	'Set this campaign as running to start sending it to your contacts. If required, any additional video submissions needing approval will be sent once approved.';

export const CONTACTLIMITTRIGGERALERTTITLE = 'Contact addition limit hit.';

export enum ActionDrawerTabsEnum {
	APPROVE = 'videos to approve',
	PENDING = 'pending tasks',
}

export const READYTOSENDTEXT = `This filter will only apply to Reel campaigns and all other campaign types will be disabled. To filter for other campaign types again, you'll need to remove this filter.`;
export const disableScriptInstructionsDescription = `For this campaign type, script and instructions can't be edited individually per recording task. To update script and instructions, go to the campaign report page.`;

export enum ApprovalsModes {
	APPROVE_SEND = 'approveAndSend',
	APPROVE_SCHEDULE = 'approveAndSchedule',
	APPROVE_OUT_OF_SENDING_WINDOW = 'approveOutOfSendingWindow',
	APPROVE_REEL_NOT_SENDING = 'approveReelNotSending',
	APPROVE_REEL_ALREADY_SENDING = 'approveReelAlreadySending',
	APPROVE_COLLECT = 'approveAndCollect',
}
