import React, { useCallback, forwardRef } from 'react';
import * as S from './Button.styles';
import { BadgeCount, Icon } from '@common/design-system/components/atoms';
import { IconWeight } from 'phosphor-react';
import { ElevationShadowsType } from '@common/design-system/global/types';

type ButtonVariantType =
	| 'primary'
	| 'outlined'
	| 'tertiary'
	| 'success'
	| 'danger'
	| 'dangerWeak'
	| 'ghost';

type ButtonSizeType = 'xs' | 'small' | 'medium' | 'large';

type ButtonIconPositionType = 'left' | 'right';

type PropTypes = {
	children?: React.ReactNode;
	text?: string;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	className?: string;
	variant?: ButtonVariantType;
	size?: ButtonSizeType;
	isFullWidth?: boolean;
	minWidth?: string;
	icon?: string;
	iconPosition?: ButtonIconPositionType;
	iconButton?: boolean;
	iconColor?: {
		default?: string;
		hover?: string;
		active?: string;
	}; // Use with caution please, icons inherit their button text color, this is to overwrite it and it's used mostly for internal components
	disabled?: boolean;
	iconWeight?: IconWeight;
	type?: 'button' | 'submit' | 'reset';
	counter?: number;
	active?: boolean;
	elevationShadow?: ElevationShadowsType;
};

const Button = forwardRef<HTMLButtonElement, PropTypes>(function Button(
	{
		children,
		text,
		onClick,
		variant = 'primary',
		className,
		size = 'medium',
		icon,
		iconPosition = 'left',
		iconButton = false,
		iconColor,
		disabled = false,
		iconWeight,
		type = 'button',
		counter,
		active,
		minWidth,
		elevationShadow,
		...rest
	}: PropTypes,
	ref,
) {
	const isIconLeft = icon && iconPosition === 'left';
	const isIconRight = icon && iconPosition === 'right';

	const getIconSizing = useCallback(() => {
		switch (size) {
			case 'xs':
				return 'xs';
			case 'small':
			case 'medium':
			case 'large':
				return 'medium';
		}
	}, [size]);

	const getCounterBadgeVariant = useCallback(() => {
		switch (variant) {
			case 'primary':
				return 'default';
			case 'outlined':
				return 'filled';
			case 'tertiary':
				return 'neutral';
			case 'success':
				return 'success';
			case 'danger':
			case 'dangerWeak':
				return 'error';
			case 'ghost':
				return 'neutral';

			default:
				return 'default';
		}
	}, [variant]);

	if (iconButton && icon) {
		return (
			<S.StyledButton
				ref={ref}
				className={className}
				size={size}
				variant={variant}
				iconButton={iconButton}
				iconColor={iconColor}
				onClick={onClick}
				disabled={disabled}
				type={type}
				elevationShadow={elevationShadow}
				{...rest}
			>
				<Icon
					iconName={icon}
					size={getIconSizing()}
					weight={iconWeight ? iconWeight : 'regular'}
				/>
			</S.StyledButton>
		);
	}

	return (
		<S.StyledButton
			ref={ref}
			className={className}
			variant={variant}
			size={size}
			iconButton={iconButton}
			onClick={onClick}
			disabled={disabled}
			type={type}
			active={active}
			minWidth={minWidth}
			elevationShadow={elevationShadow}
			{...rest}
		>
			{isIconLeft && (
				<Icon
					iconName={icon}
					size={getIconSizing()}
					weight={iconWeight ? iconWeight : 'regular'}
				/>
			)}
			{children || text}
			{isIconRight && (
				<Icon
					iconName={icon}
					size={getIconSizing()}
					weight={iconWeight ? iconWeight : 'regular'}
				/>
			)}
			{!!counter && (
				<BadgeCount
					size="xs"
					count={counter}
					variant={getCounterBadgeVariant()}
				/>
			)}
		</S.StyledButton>
	);
});

export default Button;
export type {
	ButtonVariantType,
	ButtonSizeType,
	ButtonIconPositionType,
	PropTypes as ButtonProps,
};
