import styled, { DefaultTheme, css } from 'styled-components';
import { AlertType } from './Alert';

const getBorderWidth = (type: AlertType) => {
	if (type === 'system' || type === 'gradient') {
		return '1.5px';
	}
	return '1px';
};

const getBorderColor = (type: AlertType, theme: DefaultTheme) => {
	if (type === 'info') {
		return theme.colors.primary.border.default;
	}

	if (type === 'system') {
		return theme.colors.system.border.medium;
	}

	if (type === 'gradient') {
		return 'transparent';
	}

	return theme.colors[type].border.default;
};

const getBackgroundColor = (type: AlertType, theme: DefaultTheme) => {
	if (type === 'info') {
		return theme.colors.primary.background.default;
	}

	if (type === 'system') {
		return theme.colors.system.background.xWeak;
	}

	if (type === 'gradient') {
		return theme.colors.system.background.system;
	}

	return theme.colors[type].background.default;
};

export const AlertWrapper = styled.div<{
	type: AlertType;
	hasShadow?: boolean;
}>`
	position: relative;
	display: flex;
	align-items: flex-start;
	gap: ${({ theme }) => theme.spacing.small};
	padding: ${({ theme }) => theme.spacing.medium}
		${({ theme }) => theme.spacing.large};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	${({ type, theme }) => {
		// Trick to apply gradient border.
		if (type === 'gradient') {
			return css`
				background:
					linear-gradient(
							${getBackgroundColor(type, theme)},
							${getBackgroundColor(type, theme)}
						)
						padding-box,
					${theme.colors.system.gradients.orangePink} border-box;
			`;
		}

		// Use normal background for non gradient types
		return css`
			background: ${getBackgroundColor(type, theme)};
		`;
	}}

	border: ${({ type, theme }) => {
		const borderWidth = getBorderWidth(type);
		const borderColor = getBorderColor(type, theme);

		return `${borderWidth} solid ${borderColor}`;
	}};
	width: 100%;
	box-shadow: ${({ hasShadow, theme }) =>
		hasShadow && theme.shadows.elevation.container};
`;

export const AlertIconWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

export const AlertImage = styled.img`
	width: 32px;
	height: 32px;
	border-radius: 6.4px;
`;

export const AlertContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
`;

export const AlertTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
	white-space: pre-wrap;
`;

export const AlertButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const CloseButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	position: absolute;
	right: ${({ theme }) => theme.spacing.medium};
	cursor: pointer;
`;

export const AlertInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const AlertToggleWrapper = styled.div`
	display: flex;
	align-items: center;
`;
