import { CTA } from '@app/redux/cta/types';
import { WorkspaceType } from '@app/redux/workspace/types';
import { TemplateType } from '@app/types/modules/campaign';
import { StoriesType } from '@app/types/modules/stories';
import { isValidColorFormat } from '@app/utils/isValidColorFormat';
import {
	checkIsDoubleLinkCta,
	checkIsEventCta,
	getCTAButtonTitle,
	getCTAURL,
	getEventCTADetails,
} from '@app/utils/modules/actions';
import { isVideoPlayable } from '@app/utils/modules/videos';
import { getContrast } from 'polished';

const handleTextColor = (w: string, b: string, themePrimaryColor: string) => {
	if (!isValidColorFormat(themePrimaryColor)) return b;

	const wConstrastScore = getContrast(themePrimaryColor, w);
	const bConstrastScore = getContrast(themePrimaryColor, b);

	// Use white only when the contrast score is greater than 3
	// or greater than/equal to black's contrast score
	if (wConstrastScore >= 3 || wConstrastScore >= bConstrastScore) return w;

	// otherwise, use black's
	return b;
};

const buildExtendedTheme = (
	workspace: WorkspaceType,
	template?: TemplateType,
) => {
	if (!workspace && !template) return {};

	let logo = workspace?.logo;
	if (template?.logo) logo = template?.logo;

	const {
		organizationName,
		primaryColor,
		theme: { borderRadius: { round } } = {
			borderRadius: {
				round: false,
			},
		},
	} = workspace || {};

	const themePrimaryColor = template?.primaryColor || primaryColor || '#0074E8';
	const isRoundBorderRadius = template?.borderRadius ?? round ?? false;

	const white = '#fff';
	const black = '#000';

	return {
		logo,
		name: organizationName,
		primaryColor: themePrimaryColor,
		primaryTextColor: handleTextColor(white, black, themePrimaryColor),
		roundRadius: isRoundBorderRadius,
		buttonSize: template?.buttonSize,
		mode: 'dark',
	} as any;
};

export const getPlayerTheme = (story: StoriesType) => {
	const workspace = story?.workspace as unknown as WorkspaceType;
	const template = story?.campaign?.template;

	const theme = buildExtendedTheme(workspace, template);
	return theme;
};

export function getVideoRecorder(story: StoriesType) {
	if (!!story?.assignedTo && !!story?.assignedTo?._id) {
		return story?.assignedTo;
	}

	return story?.video?.uploadedBy;
}

const getCallToActionButtonInfo = ({
	callToAction,
	isPrimaryButton,
	handleReplaceMergeTags,
}: {
	callToAction: CTA;
	isPrimaryButton: boolean;
	handleReplaceMergeTags: any;
}) => {
	return {
		buttonTitle: handleReplaceMergeTags(
			getCTAButtonTitle(callToAction, isPrimaryButton) || '',
		),
		linkUrl: handleReplaceMergeTags(
			getCTAURL(callToAction, isPrimaryButton) || '',
		),
		type: callToAction?.type,
	};
};

export function handleFormatCallToActions({
	actions,
	handleReplaceMergeTags,
}: {
	handleReplaceMergeTags: any;
	actions?: CTA[];
}) {
	const formmattedCtas: {
		title: string;
		linkUrl: string;
		buttonTitle: string;
		type: any;
	}[] = [];

	if (!actions?.length) return formmattedCtas;

	for (let i = 0; i < actions.length; i++) {
		const callToAction = actions[i];

		// Declare initial values
		let firstButtonInfo: any, secondButtonInfo: any;

		// CTA type check
		const isEventCta = checkIsEventCta(callToAction?.type);
		const isDoubleLinkCta = checkIsDoubleLinkCta(callToAction?.type);

		firstButtonInfo = getCallToActionButtonInfo({
			callToAction,
			isPrimaryButton: isDoubleLinkCta,
			handleReplaceMergeTags,
		});

		// Add extra details about the cta. In this case, event cta.
		if (isEventCta) {
			const extraInfo = getEventCTADetails(callToAction);
			firstButtonInfo = {
				...firstButtonInfo,
				...extraInfo,
				eventTitle: handleReplaceMergeTags(callToAction?.eventTitle || ''),
			};
		}

		formmattedCtas.push(firstButtonInfo);

		// In case of double cta, add the second one.
		if (isDoubleLinkCta) {
			secondButtonInfo = getCallToActionButtonInfo({
				callToAction,
				isPrimaryButton: false,
				handleReplaceMergeTags,
			});

			formmattedCtas.push(secondButtonInfo);
		}
	}

	return formmattedCtas;
}

export function checkIsVideoPlayable(story: StoriesType) {
	return (
		!!story?.video?._id &&
		!(story?.video?.status === 'Processing') &&
		isVideoPlayable(story?.video)
	);
}
