import React from 'react';

import * as S from './NavLinkButton.styles';

import {
	Badge,
	BadgeCount,
	Icon,
	StatusIndicator,
	Tooltip,
} from '@common/design-system/components/atoms';
import { emojiAndSymbolsRegex } from '@app/constants/regex';

export type NavLinkButtonProps = {
	iconName?: string;
	inactiveIconName?: string;
	text: string;
	redirectTo?: string;
	isDisabled?: boolean;
	isCollapsed?: boolean;
	handleIsCollapsed?: () => void;
	isSubMenusThreaded?: boolean;
	isNavbarCollapsed?: boolean;
	onClick?: (e?: any) => void;
	count?: number;
	ref?: any;
	redirectLinkIcon?: boolean;
	textBadge?: string;
	active?: boolean;
	menus?: NavLinkButtonProps[];
	parentMenuStyle?: 'main' | 'sub';
	parentMenuCapitalize?: boolean;
	collapseArrowDirection?: 'left' | 'right';
	className?: string;

	// Additional props for accordion menu items
	showMenuCount?: boolean;
	hideOnCollapse?: boolean;
};

export default function NavLinkButton({
	iconName,
	inactiveIconName,
	text,
	redirectTo,
	isDisabled,
	isCollapsed,
	handleIsCollapsed,
	isNavbarCollapsed,
	isSubMenusThreaded,
	onClick,
	count,
	textBadge,
	ref,
	redirectLinkIcon,
	active,
	parentMenuStyle = 'sub',
	collapseArrowDirection = 'left',
	className,
}: NavLinkButtonProps) {
	const isAMainLink = !!handleIsCollapsed && !isNavbarCollapsed;

	const isActivePage =
		typeof active === 'boolean'
			? active
			: !!redirectTo &&
				window.location.pathname.match(redirectTo) &&
				!isAMainLink;

	const isUsingCustomIcon = !!iconName && !!inactiveIconName;

	const isEmojiIcon =
		iconName && !inactiveIconName && emojiAndSymbolsRegex().test(iconName);

	const LinkComponent = ({ children, ...rest }: any) => {
		return isAMainLink ? (
			<S.LinkButtonContainer {...rest}>{children}</S.LinkButtonContainer>
		) : (
			<S.NavLinkButtonContainer {...rest}>{children}</S.NavLinkButtonContainer>
		);
	};

	const isParentMenuSubStyling = parentMenuStyle === 'sub';

	const textColor =
		!!handleIsCollapsed && isParentMenuSubStyling
			? 'system.text.medium'
			: undefined;
	const textWeight =
		!!handleIsCollapsed && isParentMenuSubStyling ? 'medium' : 'regular';
	const textSize = !!handleIsCollapsed && isParentMenuSubStyling ? 'xxs' : 'xs';

	return (
		<LinkComponent
			onClick={(e: { preventDefault: () => any }) => {
				if (isDisabled || !!onClick || !!handleIsCollapsed) {
					e.preventDefault();
				}

				onClick && onClick(e);

				if (handleIsCollapsed && isParentMenuSubStyling) {
					handleIsCollapsed();
				}
			}}
			to={redirectTo}
			aria-label={text}
			$isNavbarCollapsed={isNavbarCollapsed}
			$isAccordionHeader={!!handleIsCollapsed}
			$isSubMenusThreaded={isSubMenusThreaded}
			ref={ref}
			$isDisabled={isDisabled}
			$isActivePage={isActivePage}
			className={className}
		>
			{!!handleIsCollapsed &&
				!isNavbarCollapsed &&
				collapseArrowDirection === 'left' && (
					<S.NavLinkButtonIconWrapper
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							handleIsCollapsed && handleIsCollapsed();
						}}
					>
						<Icon
							iconName={isCollapsed ? 'arrowUpIndicator' : 'downArrowIndicator'}
							size="xs"
							color="neutral.icon.default"
						/>
					</S.NavLinkButtonIconWrapper>
				)}

			{iconName && (
				<Tooltip content={isNavbarCollapsed ? text : ''} placement="right">
					<S.IconContainer isActivePage={isActivePage}>
						{isUsingCustomIcon && (
							<>
								<Icon
									iconName={iconName}
									weight="regular"
									className="active-icon"
								/>

								<Icon
									iconName={inactiveIconName}
									weight="regular"
									className="inactive-icon"
								/>
							</>
						)}

						{!isUsingCustomIcon && (
							<Icon
								iconName={iconName}
								weight={isActivePage ? 'fill' : 'regular'}
								size={isEmojiIcon ? 'xs' : 'medium'}
							/>
						)}
					</S.IconContainer>
				</Tooltip>
			)}

			{!isNavbarCollapsed && (
				<S.NavText
					size={textSize}
					fontWeight={textWeight}
					lineHeight="medium"
					color={textColor}
					noWrap
				>
					{text}
				</S.NavText>
			)}

			{!!redirectLinkIcon && !isNavbarCollapsed && (
				<S.RedirectLinkIconWrapper>
					<Icon iconName={'preview'} size="xs" />
				</S.RedirectLinkIconWrapper>
			)}

			{!!count && !isNavbarCollapsed && (
				<S.BadgeWrapper>
					<BadgeCount count={count || 0} size="xs" />
				</S.BadgeWrapper>
			)}

			{!!textBadge && !isNavbarCollapsed && (
				<S.BadgeWrapper>
					<Badge text={textBadge} size="small" />
				</S.BadgeWrapper>
			)}

			{!!count && isNavbarCollapsed && (
				<S.StatusBulletWrapper>
					<StatusIndicator variant="primary" />
				</S.StatusBulletWrapper>
			)}

			{!!handleIsCollapsed &&
				!isNavbarCollapsed &&
				collapseArrowDirection === 'right' && (
					<S.NavLinkButtonWrapper>
						<S.NavLinkButtonIconWrapper
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								handleIsCollapsed && handleIsCollapsed();
							}}
						>
							<Icon
								iconName={
									isCollapsed ? 'arrowUpIndicator' : 'downArrowIndicator'
								}
								size="xs"
								color="neutral.icon.default"
							/>
						</S.NavLinkButtonIconWrapper>
					</S.NavLinkButtonWrapper>
				)}
		</LinkComponent>
	);
}
