// Template thumbnails
import BodyTextImagePNG from '@global/images/typography-thumbnails/body.png';
import HeaderTextImagePNG from '@global/images/typography-thumbnails/header.png';
import SubHeaderTextImagePNG from '@global/images/typography-thumbnails/sub-header.png';
import CongradulationsPNG from '@global/images/typography-thumbnails/congradulations.png';
import MarkYourCalendarPNG from '@global/images/typography-thumbnails/mark-your-calendar.png';
import TakeTheNextStepPNG from '@global/images/typography-thumbnails/take-the-next-step.png';
import YouAreInvitedPNG from '@global/images/typography-thumbnails/you-are-invited.png';
import YourTimeIsNowPNG from '@global/images/typography-thumbnails/your-time-is-now.png';
// Template jsons
import BodyTextImageJSON from '@global/json/typography-jsons/body.json';
import HeaderTextImageJSON from '@global/json/typography-jsons/header.json';
import SubHeaderTextImageJSON from '@global/json/typography-jsons/sub-header.json';
import CongradulationsJSON from '@global/json/typography-jsons/congradulations.json';
import MarkYourCalendarJSON from '@global/json/typography-jsons/mark-your-calendar.json';
import TakeTheNextStepJSON from '@global/json/typography-jsons/take-the-next-step.json';
import YouAreInvitedJSON from '@global/json/typography-jsons/you-are-invited.json';
import YourTimeIsNowJSON from '@global/json/typography-jsons/your-time-is-now.json';

// Merge images
import QRCode from '@global/images/postcard-images/qr-code.svg';
import VideoThumbnail from '@global/images/postcard-images/video-thumbnail.svg';
import WorkspaceLogo from '@global/images/postcard-images/workspace-logo.svg';
import WorkspaceBackground from '@global/images/postcard-images/workspace-background.svg';
import ContactInformation from '@global/images/postcard-images/contact-information.svg';

// Overlays
import FourBySixOverlayFront from '@global/images/postcard-images/4x6_overlay_front.png';
import FourBySixOverlayBack from '@global/images/postcard-images/4x6_overlay_back.png';
import SixByNineOverlayFront from '@global/images/postcard-images/6x9_overlay_front.png';
import SixByNineOverlayBack from '@global/images/postcard-images/6x9_overlay_back.png';
import SixByElevenOverlayFront from '@global/images/postcard-images/6x11_overlay_front.png';
import SixByElevenOverlayBack from '@global/images/postcard-images/6x11_overlay_back.png';

export const postcardImageUrls = {
	qrCode: QRCode,
	videoThumbnail: VideoThumbnail,
	workspaceLogo: WorkspaceLogo,
	workspaceBg: WorkspaceBackground,
	contactInformation: ContactInformation,

	fourBySixFrontOverlay: FourBySixOverlayFront,
	fourBySixBackOverlay: FourBySixOverlayBack,
	sixByNineFrontOverlay: SixByNineOverlayFront,
	sixByNineBackOverlay: SixByNineOverlayBack,
	sixByElevenFrontOverlay: SixByElevenOverlayFront,
	sixByElevenBackOverlay: SixByElevenOverlayBack,
};

export enum UserRolesEnum {
	ADMIN = 'admin',
	USER = 'user',
}

export enum PostcardSizeEnum {
	FOUR_BY_SIX = '4x6',
	SIX_BY_NINE = '6x9',
	SIX_BY_ELEVEN = '6x11',
}
export enum PostcardVariableNames {
	QR_CODE = 'qr-code',
	WORKSPACE_LOGO = 'workspace-logo',
	WORKSPACE_BG = 'workspace-bg',
	VIDEO_THUMBNAIL = 'video-thumbnail',
	CONTACT_INFORMATION = 'contact-information',
	FRONT_OVERLAY = 'front-overlay',
	BACK_OVERLAY = 'back-overlay',
}

export const postCardSizeOptions = [
	{
		label: '4x6 inches',
		value: PostcardSizeEnum.FOUR_BY_SIX,
	},
	{
		label: '6x9 inches',
		value: PostcardSizeEnum.SIX_BY_NINE,
	},
	{
		label: '6x11 inches',
		value: PostcardSizeEnum.SIX_BY_ELEVEN,
	},
];

export const postcardSizeMap = {
	[PostcardSizeEnum.FOUR_BY_SIX]: {
		width: 1871,
		height: 1271,
	},
	[PostcardSizeEnum.SIX_BY_NINE]: {
		width: 2771,
		height: 1871,
	},
	[PostcardSizeEnum.SIX_BY_ELEVEN]: {
		width: 3371,
		height: 1871,
	},
};

export const POSTCARD_CONTACT_INFO_SIZE = {
	[PostcardSizeEnum.FOUR_BY_SIX]: {
		width: 1160,
		height: 805,
	},
	[PostcardSizeEnum.SIX_BY_NINE]: {
		width: 1241,
		height: 888,
	},
	[PostcardSizeEnum.SIX_BY_ELEVEN]: {
		width: 1241,
		height: 928,
	},
};

export const POSTCARD_BORDER_RADIUS = {
	[PostcardVariableNames.QR_CODE]: {
		round: 16,
		square: 0,
	},
	[PostcardVariableNames.WORKSPACE_LOGO]: {
		round: 1000,
		square: 0,
	},
	[PostcardVariableNames.WORKSPACE_BG]: {
		round: 0,
		square: 0,
	},
	[PostcardVariableNames.VIDEO_THUMBNAIL]: {
		round: 16,
		square: 0,
	},
	[PostcardVariableNames.CONTACT_INFORMATION]: {
		round: 0,
		square: 0,
	},
	[PostcardVariableNames.FRONT_OVERLAY]: {
		round: 0,
		square: 0,
	},
	[PostcardVariableNames.BACK_OVERLAY]: {
		round: 0,
		square: 0,
	},
};

export const TYPOGRAPHY_TEMPLATES = [
	{
		json: HeaderTextImageJSON,
		image: HeaderTextImagePNG,
		type: 'basic',
	},
	{
		json: SubHeaderTextImageJSON,
		image: SubHeaderTextImagePNG,
		type: 'basic',
	},
	{
		json: BodyTextImageJSON,
		image: BodyTextImagePNG,
		type: 'basic',
	},
	{
		json: YourTimeIsNowJSON,
		image: YourTimeIsNowPNG,
		type: 'custom',
	},
	{
		json: YouAreInvitedJSON,
		image: YouAreInvitedPNG,
		type: 'custom',
	},
	{
		json: TakeTheNextStepJSON,
		image: TakeTheNextStepPNG,
		type: 'custom',
	},
	{
		json: MarkYourCalendarJSON,
		image: MarkYourCalendarPNG,
		type: 'custom',
	},
	{
		json: CongradulationsJSON,
		image: CongradulationsPNG,
		type: 'custom',
	},
];

export enum PolotnoElementTypeEnum {
	GROUP = 'group',
	TEXT = 'text',
	IMAGE = 'image',
	SVG = 'svg',
	LINE = 'line',
	FIGURE = 'figure',
	VIDEO = 'video',
}

export enum PostcardActivityStatusEnum {
	POSTCARD_PENDING = 'postcard_pending',
	POSTCARD_ORDERED = 'postcard_ordered',
	POSTCARD_PRINTED = 'postcard_printed',
	POSTCARD_HANDED_OVER = 'postcard_handed_over',
	POSTCARD_DELIVERED = 'postcard_delivered',
	POSTCARD_CANCELLED = 'postcard_cancelled',
	POSTCARD_RETURNED = 'postcard_returned',
	POSTCARD_FAILED = 'postcard_failed',
}
