//TODO: REFACTOR THIS GUY TO ANOTHER NAME, IS NOT ONLY FOR USER DISPLAY ANYMORE

import React from 'react';
import { TableMapperType } from '../../types';
import styled from 'styled-components';
import {
	Avatar,
	Text,
	Badge,
	Icon,
	Tooltip,
	DisplayIcon,
	LinkButton,
} from '@common/design-system/components/atoms';

type PropsType = {
	mapperField: TableMapperType;
	item: any;
};

const Wrapper = styled.div<{ onClick?: any }>`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.medium};
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

	.texts-wrapper {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing.xxs};

		.title-wrapper {
			position: relative;
			width: max-content;
		}
	}
`;
const ThumbnailBadgeWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: ${({ theme }) => theme.shapeRadius.xxs};
	position: relative;
`;
const ThumbnailWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	overflow: hidden;
	border-radius: ${({ theme }) => theme.shapeRadius.xxs};
	img {
		width: 100%;
	}
`;
const OverlayWrapper = styled.div`
	position: absolute;
	bottom: -6px;
	right: -6px;
	width: 22px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: ${({ theme }) => theme.shapeRadius.xxs};
`;

function UserDisplayCell({ mapperField, item }: PropsType) {
	const textMaxLength = mapperField?.textMaxLength || 50;
	const badgeText =
		typeof mapperField.userBadge === 'function'
			? mapperField.userBadge(item)
			: mapperField.userBadge;

	const userIconName =
		typeof mapperField.userIcon === 'function'
			? mapperField.userIcon(item)
			: mapperField.userIcon;

	const userSubTitleIconName =
		typeof mapperField.userSubTitleIconName === 'function'
			? mapperField.userSubTitleIconName(item)
			: mapperField.userSubTitleIconName;

	const userSubTitleIconColor =
		typeof mapperField.userSubTitleIconColor === 'function'
			? mapperField.userSubTitleIconColor(item)
			: mapperField.userSubTitleIconColor;

	const userIconTooltipText =
		typeof mapperField.userIconTooltipText === 'function'
			? mapperField.userIconTooltipText(item)
			: mapperField.userIconTooltipText;

	const isDisabled =
		typeof mapperField.isDisabled === 'function'
			? mapperField.isDisabled(item)
			: mapperField.isDisabled;

	const userDisplayIconProps =
		typeof mapperField?.userDisplayIconProps === 'function'
			? mapperField?.userDisplayIconProps(item)
			: mapperField?.userDisplayIconProps;

	const userThumbnailBadgeIconProps =
		typeof mapperField?.userThumbnailBadgeIconProps === 'function'
			? mapperField?.userThumbnailBadgeIconProps(item)
			: mapperField?.userThumbnailBadgeIconProps;

	const useThumbnail =
		typeof mapperField?.useUserThumbnail === 'function'
			? mapperField?.useUserThumbnail(item)
			: !!mapperField?.useUserThumbnail;

	const titleFontWeight =
		typeof mapperField?.userTitleFontWeight === 'function'
			? mapperField?.userTitleFontWeight(item)
			: mapperField?.userTitleFontWeight || 'medium';

	const subTitleFontWeight =
		typeof mapperField?.userSubTitleFontWeight === 'function'
			? mapperField?.userSubTitleFontWeight(item)
			: mapperField?.userSubTitleFontWeight || 'regular';

	const userTitleItalic =
		typeof mapperField?.userTitleItalic === 'function'
			? mapperField?.userTitleItalic(item)
			: mapperField?.userTitleItalic || false;

	const userSubTitleItalic =
		typeof mapperField?.userSubTitleItalic === 'function'
			? mapperField?.userSubTitleItalic(item)
			: mapperField?.userSubTitleItalic || false;

	const truncateText = (text: string) => {
		if (text && text.length > textMaxLength) {
			return `${text.substring(0, textMaxLength).trim()}...`;
		}
		return text;
	};

	const titleText = item[mapperField?.userTitleKey || 'name'];
	const subTitleText = item[mapperField?.userSubTitleKey || 'email'];

	const isTextTruncated =
		(titleText && titleText.length > textMaxLength) ||
		(subTitleText && subTitleText.length > textMaxLength);

	const tooltipText =
		titleText.length > textMaxLength
			? titleText
			: subTitleText?.length > textMaxLength
				? subTitleText
				: `${titleText}\n${subTitleText}`;

	return (
		<>
			<Wrapper
				onClick={
					!!mapperField?.onUserClick
						? () => mapperField?.onUserClick && mapperField?.onUserClick(item)
						: undefined
				}
			>
				{useThumbnail &&
					!userDisplayIconProps &&
					!userThumbnailBadgeIconProps && (
						<ThumbnailWrapper>
							<img
								src={item[mapperField?.userPictureKey || 'profilePicture']}
								alt={item.name}
							/>
						</ThumbnailWrapper>
					)}
				{useThumbnail &&
					!userDisplayIconProps &&
					userThumbnailBadgeIconProps && (
						<ThumbnailBadgeWrapper>
							<ThumbnailWrapper>
								<img
									src={item[mapperField?.userPictureKey || 'profilePicture']}
									alt={item.name}
								/>
							</ThumbnailWrapper>
							<OverlayWrapper>
								<DisplayIcon {...userThumbnailBadgeIconProps} />
							</OverlayWrapper>
						</ThumbnailBadgeWrapper>
					)}

				{!useThumbnail && !userDisplayIconProps && (
					<Avatar image={item.profilePicture} name={item.name} />
				)}

				{userDisplayIconProps && !useThumbnail && (
					<DisplayIcon {...userDisplayIconProps} />
				)}

				<Tooltip content={isTextTruncated ? tooltipText : ''}>
					<div className="texts-wrapper">
						<div className="title-wrapper">
							{!!mapperField?.onUserTitleClick && (
								<LinkButton
									onClick={() =>
										mapperField?.onUserTitleClick &&
										mapperField?.onUserTitleClick(item)
									}
									weight="regular"
								>
									{truncateText(item[mapperField?.userTitleKey || 'name'])}
								</LinkButton>
							)}
							{!mapperField?.onUserTitleClick && (
								<Text
									size="small"
									fontWeight={titleFontWeight}
									color={
										isDisabled ? 'system.text.medium' : 'system.text.default'
									}
									isItalic={userTitleItalic}
								>
									{truncateText(item[mapperField?.userTitleKey || 'name'])}
								</Text>
							)}
						</div>
						<div className="title-wrapper d-flex align-items-center gap-1">
							{mapperField.userSubTitleIconName && !!userSubTitleIconName && (
								<Icon
									iconName={userSubTitleIconName as string}
									color={
										userSubTitleIconColor && userSubTitleIconColor
											? userSubTitleIconColor
											: 'system.text.weak'
									}
									weight="fill"
									size="xs"
								/>
							)}
							<Text
								size="small"
								color="system.text.medium"
								fontWeight={subTitleFontWeight}
								isItalic={userSubTitleItalic}
							>
								{truncateText(item[mapperField?.userSubTitleKey || 'email'])}
							</Text>
						</div>
					</div>
				</Tooltip>

				{mapperField?.userBadge && !!badgeText && (
					<Badge text={badgeText} size="small" variant="warning" />
				)}

				{mapperField?.userIcon && !!userIconName && (
					<Tooltip content={userIconTooltipText || ''}>
						<span>
							<Icon
								iconName={userIconName}
								color="system.text.weak"
								weight="fill"
							/>
						</span>
					</Tooltip>
				)}
			</Wrapper>
		</>
	);
}

export default UserDisplayCell;
