import styled from 'styled-components';

export const Wrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const SingleValueWrapper = styled.div<{
	subLabelPrepend?: boolean;
}>`
	display: flex;
	flex-direction: ${({ subLabelPrepend }) =>
		subLabelPrepend ? 'row-reverse' : 'row'};
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const ImageWrapper = styled.div`
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: ${({ theme }) => theme.shapeRadius[50]};

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

export const CustomMultiSelectItem = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.xs};
	padding: ${({ theme }) => theme.spacing.xs};
	font-size: ${({ theme }) => theme.fonts.sizes.small};
	color: ${({ theme }) => theme.colors.primary.text.default};
	border-radius: ${({ theme }) => theme.shapeRadius.xs};
	cursor: pointer;
`;

export const LabelWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
`;

export const OptionWrapper = styled.div<{
	subLabelInNewLine?: boolean;
	subLabelPrepend?: boolean;
	hasSubLabel?: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: ${({ theme }) => theme.spacing.xs};
	cursor: pointer;

	.option-texts-wrapper {
		display: flex;
		flex-direction: ${({ subLabelInNewLine, subLabelPrepend }) =>
			(subLabelInNewLine ? 'column' : 'row') +
			(subLabelPrepend ? '-reverse' : '')};
		gap: ${({ theme, subLabelInNewLine }) =>
			subLabelInNewLine ? theme.spacing.xxxs : theme.spacing.xs};

		> :first-child {
			flex: ${({ subLabelInNewLine, hasSubLabel }) =>
				hasSubLabel ? (subLabelInNewLine ? 1 : `0 0 max-content`) : 1};
		}
	}
`;

export const NoOptionsWrapper = styled.div`
	padding: ${({ theme }) => `${theme.spacing.medium} 0`};
`;

export const IconWrapper = styled.div`
	min-width: 16px;
`;

// 6px of gap following designs for this part
export const SubLabelWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 6px;
`;
