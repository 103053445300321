import styled, { keyframes } from 'styled-components';

const slideInFadeIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOutFadeOut = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;
export const ScreenBackground = styled.div<{ isOpen?: boolean }>`
	position: absolute;
	height: 100%;
	width: 100%;
	background: ${({ theme }) => `${theme.colors.system.background.modal}`};
	opacity: ${({ isOpen }) => (isOpen ? 0.65 : 0)};
	transition: all 0.3s ease-in-out;
`;

export const ScreenCover = styled.div<{ isOpen?: boolean }>`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	min-height: 100vh;
	width: 100%;
	z-index: ${({ theme }) => theme.zIndex.modalBackdrop};
	visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

export const PaneContainer = styled.div<{
	containerWidth?: string;
	isOpen?: boolean;
}>`
	position: absolute;
	right: 0;
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.system.background.system};
	box-shadow: ${({ theme }) => theme.shadows.elevation.medium};
	z-index: ${({ theme, isOpen }) => (isOpen ? theme.zIndex.modal : -1)};
	width: ${({ containerWidth }) => containerWidth};
	height: 100%;
	animation: ${({ isOpen }) => (isOpen ? slideInFadeIn : slideOutFadeOut)} 0.3s
		ease-in-out;
`;

export const PaneContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	flex: 1;
`;

export const PaneHeader = styled.div<{ hasHeaderContent?: boolean }>`
	display: flex;
	justify-content: ${({ hasHeaderContent }) =>
		hasHeaderContent ? 'space-between' : 'flex-end'};
	align-items: center;
	padding: ${({ theme }) => theme.spacing.medium};
	border-bottom: 1px solid ${({ theme }) => theme.colors.system.border.weak};
`;

export const PaneFooter = styled.div`
	display: flex;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: ${({ theme }) => theme.colors.system.background.system};
	column-gap: ${({ theme }) => theme.spacing.medium};
	border-top: 1px solid ${({ theme }) => theme.colors.system.border.weak};
	padding: ${({ theme }) => theme.spacing.medium};
`;
