import styled from 'styled-components';

export const MessageCard = styled.div<{
	hasMessage?: boolean;
	active?: boolean;
}>`
	width: 100%;
	padding: ${({ theme }) => theme.spacing.medium};
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
	justify-content: flex-start;
	min-height: 105px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.system.border.weak};
	background-color: ${({ theme, active }) =>
		active
			? theme.colors.system.background.weak
			: theme.colors.system.background.system};
	cursor: pointer;
	transition: ${({ theme }) => theme.transitions.ease01};
	border-left: 3px solid
		${({ theme, active }) =>
			active ? theme.colors.primary.text.default : 'transparent'};

	&:hover {
		background-color: ${({ theme }) => theme.colors.system.background.weak};
	}

	.message-card-left-container {
		margin-right: ${({ theme }) => theme.spacing.small};
	}

	.message-card-message-wrapper {
		display: flex;
		align-items: center;
		width: 100%;

		.description-icon-wrapper {
			display: flex;
			align-items: center;
			gap: ${({ theme }) => theme.spacing.xxs};
			width: 100%;
		}

		.message-icon-wrapper {
			display: flex;
			align-items: center;
			gap: ${({ theme }) => theme.spacing.xs};
			width: 100%;
			height: 22.5px;
		}

		.starred-wrapper {
			min-height: 22px; //Size of the icon
			margin-left: auto;
		}
	}

	.message-card-right-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing.xs};
		position: relative;
		width: 100%;

		.message-card-info-wrapper {
			display: flex;
			align-items: center;
			width: 100%;

			.message-card-info-data-action-wrapper {
				display: flex;
				flex-direction: column;
				margin-left: ${({ theme }) => theme.spacing.small};
				margin-right: ${({ theme }) => theme.spacing.small};
				width: 100%;

				.message-card-info-title-date {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;

					.date-wrapper {
						flex: 60%;
						justify-content: flex-end;
					}
				}

				.message-card-info-menu-wrapper {
					position: absolute;
					top: 0;
					right: 0;
					height: 100%;
					display: flex;
					justify-content: center;
				}
			}
		}

		.message-card-typing-wrapper {
			display: flex;
			align-items: center;
			gap: ${({ theme }) => theme.spacing.xs};
		}
	}
`;

export const AvatarWithStatus = styled.div`
	position: relative;
`;
