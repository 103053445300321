import styled from 'styled-components';

export const REWrapper = styled.div<{ hasError?: boolean }>`
	strong {
		font-weight: ${({ theme }) => theme.fonts.weights.bold};
	}

	//Main container
	.ql-container {
		border: 1px solid ${({ theme }) => theme.colors.system.border.weak};
		border-radius: ${({ theme }) =>
			`0 0 ${theme.shapeRadius.small} ${theme.shapeRadius.small}`};
		background-color: ${({ theme }) => theme.colors.system.background.system};
	}

	//Toolbar
	.ql-toolbar {
		border: 1px solid ${({ theme }) => theme.colors.system.border.weak};
		border-radius: ${({ theme }) =>
			`${theme.shapeRadius.small} ${theme.shapeRadius.small} 0 0`};
		background-color: ${({ theme }) => theme.colors.system.background.system};

		.ql-formats {
			margin-right: 9px !important;

			&.insert-merge-tags {
				button {
					font-size: ${({ theme }) => theme.fonts.sizes.medium};
				}
			}
		}
	}

	//Editor
	.ql-editor {
		border-radius: ${({ theme }) =>
			`0 0 ${theme.shapeRadius.small} ${theme.shapeRadius.small}`};

		&:focus,
		&:active {
			box-shadow: ${({ theme, hasError }) =>
				!hasError && theme.shadows.controls.selected};
			border: 1px solid
				${({ theme, hasError }) =>
					hasError ? 'initial' : theme.colors.primary.border.default};
			outline: none;
		}

		// Placeholder
		&.ql-blank {
			&::before {
				color: ${({ theme }) => theme.colors.system.text.weak};
				font-style: normal;
				font-size: ${({ theme }) => theme.fonts.sizes.small};
			}
		}
	}
`;
