export const INITIAL_HEIGHT = 378;

const allowedKeys = [
	'Backspace',
	'Delete',
	'ArrowUp',
	'ArrowDown',
	'ArrowLeft',
	'ArrowRight',
];

export { allowedKeys };

export const TagsInputText = {
	WARNING: 'Please review spacing before and after your merge tag(s)',
};

export enum GrammarWarningEnum {
	MERGE_TAG_SPACE_AROUND = 'mergeTagSpaceAround',
	MERGE_TAG_SQUARE_BRACKET = 'mergeTagSquareBracket',
	MERGE_TAG_CURLY_BRACES = 'mergeTagCurlyBraces',
}

export const MERGE_TAG_SPACING_WARNING =
	'Please check spacing around merge tags before sending';

export const MERGE_TAG_INVALID_FORMAT_WARNING =
	'Use the merge tag menu to insert valid tags. Manually typed tags in invalid format, including those with brackets, won’t work';
