import React, {
	createContext,
	useState,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@app/redux/types';
import {
	TemplateButtonSizesEnum,
	TemplateGenericTextValues,
	TemplateProviderFieldsSetter,
	TemplateProviderStates,
} from './constant';
import { templateFieldsValidator } from './utils';
import isEmptyValue from '@app/utils/isEmptyValue';
import { TemplateType, CampaignOverlayType } from '@app/types/modules/campaign';
import { ProviderStatesType } from './types';
import { videoViewerEnum } from '@app/constants/modules/campaign';
import { CampaignType } from '@app/redux/campaign/types';
import { WorkspaceType } from '@app/redux/workspace/types';
import { checkIsReels } from '@app/utils/modules/campaigns';

type TemplatesContextType = {
	storedTemplateFields: Partial<TemplateType>;
	storedCampaignOverlay: CampaignOverlayType;
	defaultFields: Partial<TemplateType>;
	templateFieldsErrors: string[];
	isInvalid: boolean;
	templatesProviderMode: ProviderStatesType;
	setTemplatesProviderMode: (mode: ProviderStatesType) => void;
	validateTemplateFields: (
		fields: Partial<TemplateType>,
		callback?: () => void,
	) => Promise<boolean>;
	setFields: (
		key: string,
		value: Partial<TemplateType> | Partial<CampaignOverlayType> | null,
		clean?: boolean,
	) => void;
};

const templatesContext = createContext<TemplatesContextType>({
	storedTemplateFields: {},
	storedCampaignOverlay: '',
	defaultFields: {},
	templateFieldsErrors: [],
	isInvalid: false,
	templatesProviderMode: TemplateProviderStates.DEFAULT,
	setTemplatesProviderMode: () => {},
	validateTemplateFields: () => Promise.resolve(false),
	setFields: () => {},
});

function getProviderModeInitialState(campaign: {
	[key: string]: any;
}): TemplateProviderStates.SAVED | TemplateProviderStates.DEFAULT {
	return campaign?.template?.id
		? TemplateProviderStates.SAVED
		: TemplateProviderStates.DEFAULT;
}

function getOverlayInitialState(
	workspace: { [key: string]: any },
	campaign: { [key: string]: any },
): CampaignOverlayType {
	return (
		campaign?.overlay?._id || campaign?.overlay || workspace?.overlay || null
	);
}

function getTemplatesFieldsState(
	workspace: WorkspaceType,
	campaign: CampaignType,
	state?: ProviderStatesType,
): Partial<TemplateType> {
	const providerInitialState = state || getProviderModeInitialState(campaign);
	const isReelsCampaign = checkIsReels(campaign?.personalization);

	const templateType = isReelsCampaign
		? videoViewerEnum.VIDEO_PAGE_REELS
		: videoViewerEnum.VIDEO_PAGE;

	if (providerInitialState === TemplateProviderStates.DEFAULT) {
		return {
			name: 'Default',
			type: templateType,
			borderRadius: workspace?.theme?.borderRadius?.round,
			logo: workspace?.logo,
			backgroundImage: workspace?.theme?.backgroundImage?.url || null,
			thumbnailImage: workspace?.theme?.thumbnail || null,
			primaryColor: workspace?.primaryColor,
			playButtonText: TemplateGenericTextValues.playButtonText,
			snapchatBackgroundImage:
				workspace?.theme?.template?.snapchatBackgroundImage || null,
			headerText: TemplateGenericTextValues.headerText,
			endHeaderText: TemplateGenericTextValues.endHeaderText,
			bodyText: '',
			buttonSize: TemplateButtonSizesEnum.LARGE,
		};
	}

	if (providerInitialState === TemplateProviderStates.SAVED) {
		return {
			...campaign?.template,
			name: campaign?.template?.name,
			type: campaign?.template?.type || templateType,
			borderRadius: campaign?.template?.borderRadius,
			logo: campaign?.template?.logo,
			backgroundImage: campaign?.template?.backgroundImage,
			thumbnailImage: campaign?.template?.thumbnailImage,
			snapchatBackgroundImage: campaign?.template?.snapchatBackgroundImage,
			playButtonText:
				campaign?.template?.playButtonText ||
				TemplateGenericTextValues.playButtonText,
			headerText:
				campaign?.template?.headerText || TemplateGenericTextValues.headerText,
			bodyText: campaign?.template?.bodyText || '',
			endHeaderText:
				campaign?.template?.endHeaderText ||
				TemplateGenericTextValues.endHeaderText,
			primaryColor:
				campaign?.template?.primaryColor &&
				!isEmptyValue(campaign?.template?.primaryColor)
					? campaign?.template?.primaryColor
					: workspace?.primaryColor,
			buttonSize:
				campaign?.template?.buttonSize || TemplateButtonSizesEnum.LARGE,
		};
	}

	return {
		name: 'Untitled template',
		type: templateType,
		borderRadius: workspace?.theme?.borderRadius?.round,
		logo: workspace?.logo || null,
		backgroundImage: workspace?.theme?.backgroundImage?.url || null,
		thumbnailImage: workspace?.theme?.thumbnailImage || null,
		primaryColor: workspace?.primaryColor || null,
		playButtonText: TemplateGenericTextValues.playButtonText,
		snapchatBackgroundImage:
			workspace?.theme?.template?.snapchatBackgroundImage || null,
		headerText: TemplateGenericTextValues.headerText,
		endHeaderText: TemplateGenericTextValues.endHeaderText,
		bodyText: '',
		buttonSize: TemplateButtonSizesEnum.LARGE,
	};
}

export default function TemplatesProvider({
	children,
}: {
	children: ReactNode;
	rest?: any;
}) {
	// Init selector and support methods used in the provider config

	const { savedCampaign, workspaceInfo } = useSelector(
		({
			campaign: { savedCampaign },
			workspace: { workspaceInfo },
		}: RootState) => ({
			savedCampaign,
			workspaceInfo,
		}),
	);

	/** Provider states */

	const [templatesProviderMode, setTemplatesProviderMode] =
		useState<ProviderStatesType>(getProviderModeInitialState(savedCampaign));

	// Templates - video page template stored fields and errors
	const [storedTemplateFields, setStoredTemplateFields] = useState<
		Partial<TemplateType>
	>(getTemplatesFieldsState(workspaceInfo, savedCampaign));

	const [templateFieldsErrors, setTemplateFieldsErrors] = useState<string[]>(
		[],
	);

	const hasTemplateErrors = useMemo(
		() => templateFieldsErrors.length > 0,
		[templateFieldsErrors],
	);

	// Overlay - video page overlay stored fields and errors
	const [storedCampaignOverlay, setStoredCampaignOverlay] = useState<string>(
		getOverlayInitialState(workspaceInfo, savedCampaign),
	);

	/** Provider validators: Templates */

	const validateTemplateFields = async (
		fields: Partial<TemplateType>,
		callback?: () => void,
	) => {
		try {
			await templateFieldsValidator(fields);
			setTemplateFieldsErrors([]);
			if (callback) {
				callback();
			}

			return true;
		} catch (errors: any) {
			setTemplateFieldsErrors(errors);
			return false;
		}
	};

	/** Provider setters */

	// Set Template fields
	const setFields: TemplatesContextType['setFields'] = async (
		key,
		value,
		clean?,
	) => {
		switch (key) {
			case TemplateProviderFieldsSetter.TEMPLATE:
				setStoredTemplateFields((prev: Partial<TemplateType>) => {
					const templateValue = value as TemplateType;
					const fieldsFormat = clean
						? {
								...TemplateGenericTextValues,
								...templateValue,
								type: templateValue.type || videoViewerEnum.VIDEO_PAGE,
							}
						: { ...prev, ...templateValue };

					validateTemplateFields(fieldsFormat as TemplateType);
					return fieldsFormat;
				});

				break;
			case TemplateProviderFieldsSetter.OVERLAY:
				const overlayValue = value as CampaignOverlayType;
				setStoredCampaignOverlay(overlayValue);
				break;
			default:
				break;
		}
	};

	/** Provider effects */

	// Set providers mode to saved when a template is present in the campaign, prevent duplication of new template
	useEffect(() => {
		if (savedCampaign?.template?.id) {
			const storedTemplateFields = getTemplatesFieldsState(
				workspaceInfo,
				savedCampaign,
				TemplateProviderStates.SAVED,
			);

			setTemplatesProviderMode(TemplateProviderStates.SAVED);

			setStoredTemplateFields(storedTemplateFields);
			validateTemplateFields(storedTemplateFields);
		}
	}, [savedCampaign.template]);

	// Set proper fields and clean errors on new/default template selection
	useEffect(() => {
		if (
			templatesProviderMode === TemplateProviderStates.NEW ||
			templatesProviderMode === TemplateProviderStates.DEFAULT
		) {
			setStoredTemplateFields(
				getTemplatesFieldsState(
					workspaceInfo,
					savedCampaign,
					templatesProviderMode,
				),
			);

			setTemplateFieldsErrors([]);
		}
	}, [templatesProviderMode]);

	// Validate fields on component mount
	useEffect(() => {
		if (storedTemplateFields) {
			validateTemplateFields(storedTemplateFields);
		}
	}, []);

	return (
		<templatesContext.Provider
			value={{
				storedTemplateFields,
				storedCampaignOverlay,
				defaultFields: getTemplatesFieldsState(
					workspaceInfo,
					savedCampaign,
					TemplateProviderStates.DEFAULT,
				),
				templateFieldsErrors,
				isInvalid: hasTemplateErrors,
				templatesProviderMode,
				setTemplatesProviderMode,
				validateTemplateFields,
				setFields,
			}}
		>
			{children}
		</templatesContext.Provider>
	);
}

export function useTemplatesContext() {
	return useContext(templatesContext);
}
