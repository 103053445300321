import React from 'react';
import { toast } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import * as S from './Toast.styles';
import { LinkButton, Text, Icon } from '../../atoms';

import {
	GoodkindDSTheme,
	type MergedTheme,
} from '@common/design-system/global/theme';

export type ToastPropsType = {
	message?: string;
	title?: string;
	link?: string;
	linkText?: string;
	linkClick?: () => void;
	loading?: boolean;
	type: 'info' | 'success' | 'warning' | 'error';
	inlineLink?: boolean;
};

const ToastComponent = ({
	message,
	type,
	title,
	link,
	linkText,
	linkClick,
	loading,
	inlineLink = false,
}: ToastPropsType) => {
	const icon = {
		info: 'info',
		success: 'checkCircle',
		warning: 'warning',
		error: 'warningCircle',
	};

	const textColor = {
		info: 'system.text.default',
		success: 'success.text.default',
		warning: 'warning.text.default',
		error: 'error.text.default',
	};

	const iconColor = {
		info: 'primary.icon.strong',
		success: 'success.icon.default',
		warning: 'warning.icon.default',
		error: 'error.icon.default',
	};

	return (
		<ThemeProvider theme={GoodkindDSTheme as MergedTheme}>
			<S.ToastWrapper type={type}>
				<S.ToastIconWrapper>
					<Icon iconName={icon[type]} color={iconColor[type]} />
				</S.ToastIconWrapper>
				<S.ToastContentWrapper>
					{(!!title || !!message) && (
						<S.ToastTextWrapper inlineLink={inlineLink}>
							{!!title && (
								<Text
									fontWeight="medium"
									lineHeight="large"
									size="small"
									color={textColor[type]}
								>
									{title}
								</Text>
							)}

							{!!link && !message && (
								<LinkButton href={link} disabled={loading}>
									{linkText || link}
								</LinkButton>
							)}

							{!!linkClick && !message && (
								<LinkButton onClick={linkClick} disabled={loading}>
									{linkText || link}
								</LinkButton>
							)}

							{!!message && !link && (
								<Text size="small" color={textColor[type]} lineHeight="large">
									{message}
								</Text>
							)}
						</S.ToastTextWrapper>
					)}
				</S.ToastContentWrapper>
			</S.ToastWrapper>
		</ThemeProvider>
	);
};

const showToast = ({
	message,
	type,
	title,
	link,
	linkText,
	inlineLink = false,
	autoClose = 5000,
	toastId,
}: {
	message: string;
	type: ToastPropsType['type'];
	title?: string;
	link?: string;
	linkText?: string;
	inlineLink?: boolean;
	autoClose?: false | number;
	toastId?: string;
}) => {
	return toast(
		<ToastComponent
			message={message}
			type={type}
			title={title}
			link={link}
			linkText={linkText}
			inlineLink={inlineLink}
		/>,
		{
			closeButton: false,
			autoClose: autoClose,
			toastId: toastId,
		},
	);
};

export { showToast, ToastComponent };
