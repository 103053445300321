import React from 'react';
import { TableMapperType } from '../../types';
import { Badge } from '@common/design-system/components/atoms';

type PropsType = {
	mapperField: TableMapperType;
	item: any;
};

function BadgeCell({ mapperField, item }: PropsType) {
	const badgeData =
		mapperField?.badge &&
		(typeof mapperField.badge === 'function'
			? mapperField.badge(item)
			: mapperField.badge);

	const badgeId =
		typeof badgeData?.id === 'function' ? badgeData?.id(item) : badgeData?.id;

	const badgeIconName =
		typeof badgeData?.icon === 'function'
			? badgeData?.icon(item)
			: badgeData?.icon;

	const badgeVariant =
		typeof badgeData?.variant === 'function'
			? badgeData?.variant(item)
			: badgeData?.variant;

	const badgeText =
		typeof badgeData?.text === 'function'
			? badgeData?.text(item)
			: badgeData?.text;

	const badgeIconWeight =
		typeof badgeData?.iconWeight === 'function'
			? badgeData?.iconWeight(item)
			: badgeData?.iconWeight;

	const badgeTextMaxLength = mapperField?.badgeTextMaxLength || 20;

	const truncateText = (text?: string) => {
		if (text && text.length > badgeTextMaxLength) {
			return `${text.substring(0, badgeTextMaxLength).trim()}...`;
		}
		return text;
	};

	return (
		<>
			{badgeData && (badgeText || badgeIconName) && (
				<Badge
					id={badgeId}
					iconBadge={!badgeText}
					text={truncateText(badgeText)}
					size={badgeData?.size}
					iconName={badgeIconName}
					variant={badgeVariant}
					iconWeight={badgeIconWeight}
					squared={badgeData?.squared}
					onClick={badgeData?.onClick}
					onRemove={badgeData?.onRemove}
					tooltipProps={(badgeData as any)?.tooltipProps} //not recognized the type
				/>
			)}
		</>
	);
}

export default BadgeCell;
