import {
	POSTCARD_BORDER_RADIUS,
	POSTCARD_CONTACT_INFO_SIZE,
	postcardImageUrls,
	PostcardSizeEnum,
	PostcardVariableNames,
} from '@app/constants/modules/postcard';
import convertAssetToBase64 from '@app/utils/convertAssetToBase64';
import { CreativeEditorProps } from '@common/design-system/components/organisms/CreativeEditor/CreativeEditor';
import { getPostcardOverlay } from '@common/design-system/components/organisms/CreativeEditor/utils';
import { StoreType } from 'polotno/model/store';
import { getImageSize } from 'polotno/utils/image';

export function getPostcardStoreJSON(store: StoreType) {
	// We need to only remove custom fonts without url
	// because they'd cause an error when sending the postcard

	const { fonts, ...rest } = store.toJSON();

	const validFonts = fonts.filter((font) => !!font?.url);

	return {
		...rest,
		fonts: validFonts,
	};
}

function transformTemplateMergeTags(
	templateJSON: any,
	format: '{} to []' | '[] to {}',
) {
	const templateDuplicate = JSON.parse(JSON.stringify(templateJSON));

	const pages = templateDuplicate?.pages;

	if (!pages) {
		return templateDuplicate;
	}

	const transform = (child: any) => {
		const text = child?.text;
		const isAText = child?.type === 'text';
		const isAgroup = child?.type === 'group';

		if (!child || !text || !isAText) {
			return child;
		}

		if (isAgroup) {
			const children = child?.children;

			if (!children) {
				return child;
			}

			for (const child of children) {
				transform(child);
			}

			return child;
		}

		let resultingText = text;
		if (format === '{} to []') {
			resultingText = text.replace(/\{(.*?)\}/g, '[$1]');
		}

		if (format === '[] to {}') {
			resultingText = text.replace(/\[(.*?)\]/g, '{$1}');
		}
		child.text = resultingText;
	};

	for (const page of pages) {
		const children = page?.children;

		if (!children || !children.length) {
			continue;
		}

		for (const child of children) {
			transform(child);
		}
	}

	return templateDuplicate;
}
export function handleLoadTemplateToStore(store: StoreType, templateJSON: any) {
	const transformedJSON = transformTemplateMergeTags(templateJSON, '{} to []');

	store.loadJSON(transformedJSON);
}

export function handleGetTemplateFromStore(store: StoreType) {
	const storeJSON = getPostcardStoreJSON(store);

	return transformTemplateMergeTags(storeJSON, '[] to {}');
}

function checkChildIsAnOverlay(child: any) {
	return (
		child?.custom?.name === PostcardVariableNames.FRONT_OVERLAY ||
		child?.custom?.name === PostcardVariableNames.BACK_OVERLAY
	);
}
export function checkIsPostcardTemplateEmpty(templateJSON: any) {
	const pages = templateJSON?.pages;

	if (!pages) {
		return true;
	}

	for (const page of pages) {
		const children = page?.children;
		const hasOnlyOverlay =
			children.length === 1 && checkChildIsAnOverlay(children[0]);

		if (!children || !children.length || hasOnlyOverlay) {
			continue;
		}

		return false;
	}

	return true;
}

export function getMergeImageUrl(
	variableName: PostcardVariableNames,
	customMergeImages?: CreativeEditorProps['customMergeImages'],
) {
	let imageUrl = '';

	const customImage =
		customMergeImages && customMergeImages[variableName]
			? customMergeImages[variableName]
			: '';

	if (variableName === PostcardVariableNames.QR_CODE) {
		imageUrl = customImage || postcardImageUrls.qrCode;
	}
	if (variableName === PostcardVariableNames.WORKSPACE_LOGO) {
		imageUrl = customImage || postcardImageUrls.workspaceLogo;
	}
	if (variableName === PostcardVariableNames.VIDEO_THUMBNAIL) {
		imageUrl = customImage || postcardImageUrls.videoThumbnail;
	}
	if (variableName === PostcardVariableNames.WORKSPACE_BG) {
		imageUrl = customImage || postcardImageUrls.workspaceBg;
	}

	return imageUrl;
}
export async function handleAddMergeImageToStore(
	store: StoreType,
	variableName: PostcardVariableNames,
	borderRadiusField: 'round' | 'square',
	customMergeImages?: CreativeEditorProps['customMergeImages'],
) {
	const imageUrl = getMergeImageUrl(variableName, customMergeImages);

	const borderRadius = POSTCARD_BORDER_RADIUS[variableName][borderRadiusField];

	// Load the local image and convert to base64
	const base64data = await convertAssetToBase64(imageUrl);

	// get image size
	const { width: originalWidth, height: originalHeight } =
		await getImageSize(imageUrl);
	const aspectRatio = originalWidth / originalHeight;

	// Scale down the image to fit the canvas
	const width = 500;
	const height = width / aspectRatio;

	store.activePage.addElement({
		type: 'image',
		src: base64data,
		// Place at the center of the canvas
		x: store.width / 2 - width / 2,
		y: store.height / 2 - height / 2,

		custom: {
			variable: variableName,
		},
		width: width,
		height: height,
		cornerRadius: borderRadius,

		// keep aspect ratio
		keepRatio: true,
		// can element be moved and rotated
		draggable: true,
		// can we change content of element?
		contentEditable: false,
		// can we change style of element?
		styleEditable: false,
		// can we resize element?
		resizable: true,
	});
}

export function handleAddPagesToStore(store: StoreType, pages?: string[]) {
	if (!store) return;

	pages = pages?.length ? pages : ['Front', 'Back'];

	for (const page of pages) {
		store.addPage({
			custom: {
				name: page || 'Untitled page',
			},
		});
	}
}

export function handleRemoveOverlay(store: StoreType) {
	const ids: string[] = [];
	store.pages.forEach((page) => {
		page.children.forEach((child) => {
			if (
				child.custom?.name === 'front-overlay' ||
				child.custom?.name === 'back-overlay'
			) {
				ids.push(child.id);
			}
		});
	});
	store.deleteElements(ids);
}

export async function handleAddOverlay(
	store: StoreType,
	postcardSize: PostcardSizeEnum,
) {
	const pages = store.pages;

	const frontPageOverlay = getPostcardOverlay('front', postcardSize);
	const backPageOverlay = getPostcardOverlay('back', postcardSize);

	if (!frontPageOverlay && !backPageOverlay) return;

	handleRemoveOverlay(store);

	for (const page of pages) {
		const isFrontPage = page.custom.name === 'Front';
		const isBackPage = page.custom.name === 'Back';

		if (isFrontPage && !!frontPageOverlay) {
			await handleAddOverlayToStore(
				store,
				page,
				frontPageOverlay,
				PostcardVariableNames.FRONT_OVERLAY,
			);
		}

		if (isBackPage && !!backPageOverlay) {
			await handleAddOverlayToStore(
				store,
				page,
				backPageOverlay,
				PostcardVariableNames.BACK_OVERLAY,
			);

			// We need to add the contact information placeholder to the back page
			// To cater for postcards sent in preview mode
			await handleAddContactInfoPlaceholder(store, page, postcardSize);
		}
	}

	// Move the overlay to the bottom
	store.pages.forEach((page) => {
		const overlay = page.children.find(
			(child) =>
				child.custom?.name === 'front-overlay' ||
				child.custom?.name === 'back-overlay',
		);

		if (overlay) {
			page.moveElementsBottom([overlay.id]);
		}
	});
}

async function handleAddOverlayToStore(
	store: StoreType,
	page: StoreType['pages'][number],
	overlaySrc: string,
	customName: PostcardVariableNames,
) {
	if (overlaySrc) {
		// Load the local image and convert to base64
		const base64data = await convertAssetToBase64(overlaySrc);

		page.addElement({
			type: 'image',
			x: 0,
			y: 0,
			width: store.width,
			height: store.height,
			src: base64data,
			custom: {
				name: customName,
			},
			alwaysOnTop: false,
			contentEditable: false,
			draggable: false,
			removable: false,
			resizable: false,
			selectable: false,
			showInExport: false,
			styleEditable: false,
		});
	}
}

async function handleAddContactInfoPlaceholder(
	store: StoreType,
	page: StoreType['pages'][number],
	postcardSize: PostcardSizeEnum,
) {
	// Remove existing contact information placeholder
	const existingContactInfo = page.children.find(
		(child) =>
			child.custom?.variable === PostcardVariableNames.CONTACT_INFORMATION,
	);

	if (existingContactInfo) {
		store.deleteElements([existingContactInfo.id]);
	}

	const base64data = await convertAssetToBase64(
		postcardImageUrls.contactInformation,
	);
	const width = POSTCARD_CONTACT_INFO_SIZE[postcardSize].width;
	const height = POSTCARD_CONTACT_INFO_SIZE[postcardSize].height;

	page.addElement({
		type: 'image',
		x: store.width - width,
		y: store.height - height,
		width: width,
		height: height,
		src: base64data,
		custom: {
			variable: PostcardVariableNames.CONTACT_INFORMATION,
		},
		alwaysOnTop: true,
		contentEditable: false,
		draggable: false,
		removable: false,
		resizable: false,
		selectable: false,
		showInExport: false,
		styleEditable: false,
		visible: false, // Hide the contact information placeholder from the users.
	});
}
