import React from 'react';
import { observer } from 'mobx-react-lite';
import { SectionTab } from 'polotno/side-panel';

import { Icon } from '@common/design-system/components/atoms';
import {
	PostcardSizeEnum,
	postCardSizeOptions,
} from '@app/constants/modules/postcard';
import { CardButton } from '@common/design-system/components/molecules';
import { useCreativeEditorContext } from '../../providers/CreativeEditorProvider';
import { getPostcardWidthAndHeight } from '../../utils';

type SectionTabProps = {
	onClick: any;
	active: boolean;
};

// define the new custom section
const PageSizesPanel = {
	name: 'sizes',
	Tab: (props: SectionTabProps) => (
		<SectionTab name="Sizes" {...props}>
			<Icon
				iconName="resize"
				color={props.active ? 'primary.text.hover' : 'system.icon.default'}
			/>
		</SectionTab>
	),
	// we need observer to update component automatically on any store changes
	Panel: observer(({ store }: { store: any }) => {
		const { handleAddOverlay } = useCreativeEditorContext();
		const handleSetSize = (size: PostcardSizeEnum) => {
			const { width, height } = getPostcardWidthAndHeight(size);
			store.setSize(width, height);
			store.set({
				custom: {
					...store.custom,
					size,
				},
			});

			if (handleAddOverlay) {
				handleAddOverlay(store, size);
			}
		};

		return (
			<div>
				{postCardSizeOptions.map(({ label, value }, i) => {
					const { width, height } = getPostcardWidthAndHeight(value);
					return (
						<CardButton
							size="medium"
							text={label}
							subText={`${width} x ${height}`}
							key={i}
							icon="arrows-out"
							className="w-100 my-3"
							active={store.width === width && store.height === height}
							onClick={() => handleSetSize(value)}
						>
							{label}
						</CardButton>
					);
				})}
			</div>
		);
	}),
};

export default PageSizesPanel;
