import React from 'react';

import { Icon } from '@common/design-system/components/atoms';

import * as S from './DisplayIcon.styles';
import { IconWeight } from '@phosphor-icons/react';
import { IconProps } from '../Icon/Icon';

export type PropsType = {
	iconName: string;
	iconSize?: IconProps['size'];
	size?: 'small' | 'medium' | 'large';
	iconColor?: string;
	bgColor?: string;
	iconWeight?: IconWeight;
	variant?:
		| 'primary'
		| 'system'
		| 'tertiary'
		| 'info'
		| 'error'
		| 'highlight5'
		| 'accent'
		| 'highlight2'
		| 'warning';
	round?: boolean;
};

function DisplayIcon({
	iconName,
	iconSize,
	size = 'medium',
	iconColor,
	bgColor,
	iconWeight = 'regular',
	variant = 'primary',
	round = false,
}: PropsType) {
	const variantColorsMap = {
		primary: {
			bg: 'primary.background.default',
			icon: 'primary.icon.strong',
		},
		system: {
			bg: 'system.background.weak',
			icon: 'system.text.medium',
		},
		tertiary: {
			bg: 'neutral.border.default',
			icon: 'system.background.system',
		},
		info: {
			bg: 'primary.background.default',
			icon: 'primary.icon.strong',
		},
		error: {
			bg: 'error.background.default',
			icon: 'error.icon.default',
		},
		highlight5: {
			bg: 'highlight5.background.default',
			icon: 'highlight5.icon.default',
		},
		accent: {
			bg: 'accent.background.default',
			icon: 'accent.icon.default',
		},
		highlight2: {
			bg: 'highlight2.background.default',
			icon: 'highlight2.icon.default',
		},
		warning: {
			bg: 'warning.background.default',
			icon: 'warning.icon.default',
		},
	};

	const computedBgColor = bgColor || variantColorsMap[variant].bg;
	const computedIconColor = iconColor || variantColorsMap[variant].icon;

	return (
		<S.DisplayIconWrapper color={computedBgColor} size={size} round={round}>
			<Icon
				iconName={iconName}
				size={iconSize || 'large'}
				customNumberSize={size === 'small' && !iconSize ? 9.75 : undefined}
				color={computedIconColor}
				weight={iconWeight}
			/>
		</S.DisplayIconWrapper>
	);
}

export default DisplayIcon;
export type { PropsType as DisplayIconProps };
