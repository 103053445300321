import Joi from '@hapi/joi';

import { FilterOptions, orderObject } from '@app/types';
import { colorRegex, RouteRegex } from '@app/constants/regex';

import {
	BuildTabName,
	placeholderVideoIds,
	TemplateCampaignValidatorMessages,
	TemplateValidatorMessages,
} from './constant';
import { CTAListEnum, CTASchemas } from '@app/constants/cta';
import {
	TemplateCampaignFieldsValidatorType,
	TemplatesTabsType,
} from './types';
import { CampaignType } from '@app/redux/campaign/types';
import { videoViewerEnum } from '@app/constants/modules/campaign';
import { env } from '@app/env';
import { CTATypeEnum } from '@app/constants/modules/ctas';
import { orderTypes } from '@app/constants';

const tabs: [string, string][] = Object.entries(BuildTabName);

export function getTemplateTabs({
	activeTab,
	navigate,
	onSelect,
	errors,
	warnings,
	disabledTabs,
}: {
	activeTab: string;
	navigate: (s: string) => void;
	onSelect?: (s: { key: string; name: string }) => void;
	errors?: any;
	warnings?: any;
	disabledTabs?: string[];
}) {
	return tabs.map(([key, name]) => {
		if (disabledTabs?.includes(name)) {
			return;
		}

		return {
			text: name,
			isActive: activeTab === key,
			onSelect: (): void => {
				navigate(key);
				onSelect && onSelect({ key: key, name: name });
			},
			hasError: !!errors[name],
			hasWarning: !!warnings[name],
		};
	});
}

export function getTabPath(location: string): TemplatesTabsType {
	const splittedPath = location.split('/');
	return splittedPath[splittedPath.length - 1] as TemplatesTabsType;
}

export function getPreviewContainerNode(): Element | null {
	/** This id comes from the preview container inside the Templates file */
	return document.querySelector('#template-preview-container');
}

// Validator schemas

const templateValidatorSchema = Joi.object({
	name: Joi.string()
		.required()
		.messages({
			'any.required': `${TemplateValidatorMessages.templateNameRequired}`,
			'string.empty': `${TemplateValidatorMessages.templateNameRequired}`,
		}),
	type: Joi.string().required(),
	position: Joi.string().allow(null, ''),
	borderRadius: Joi.boolean(),
	logo: Joi.string().allow(null, ''),
	backgroundImage: Joi.string().allow(null, ''),
	thumbnailImage: Joi.string().allow(null, ''),
	snapchatBackgroundImage: Joi.string().allow(null, ''),
	playButtonText: Joi.string().allow(null, ''),
	buttonSize: Joi.string().allow(null, ''),
	bodyText: Joi.string().allow(null, ''),
	headerText: Joi.string().allow(null, ''),
	endHeaderText: Joi.string().allow(null, ''),
	primaryColor: Joi.string()
		.pattern(colorRegex)
		.required()
		.messages({
			'any.required': `${TemplateValidatorMessages.primaryColorRequired}`,
			'string.empty': `${TemplateValidatorMessages.primaryColorRequired}`,
			'string.pattern.base': `${TemplateValidatorMessages.primaryColorInvalid}`,
		}),
	secondaryColor: Joi.string()
		.pattern(colorRegex)
		.messages({
			'string.pattern.base': `${TemplateValidatorMessages.secondaryColorInvalid}`,
		})
		.allow(null, ''),

	location: Joi.string(),
	id: Joi.string(),
	_id: Joi.string(),
	workspace: Joi.string(),
	createdAt: Joi.string(),
	updatedAt: Joi.string(),
	__v: Joi.number(),
	formattedUpdatedAt: Joi.string(),
	formattedCreatedAt: Joi.string(),
	brightness: Joi.string(),
});
const templateWidgetFields = {
	widgetDomain: Joi.string()
		.required()
		.messages({
			'any.required': `${TemplateCampaignValidatorMessages.widgetDomainRequired}`,
			'string.empty': `${TemplateCampaignValidatorMessages.widgetDomainRequired}`,
		}),
	widgetPath: Joi.string()
		.pattern(RouteRegex)
		.required()
		.messages({
			'any.required': `${TemplateCampaignValidatorMessages.widgetPathRequired}`,
			'string.empty': `${TemplateCampaignValidatorMessages.widgetPathRequired}`,
			'string.pattern.base': `${TemplateCampaignValidatorMessages.widgetPathInvalid}`,
		}),
	widgetHideOnMobile: Joi.boolean(),
};

const templateCampaignSettingsSchema = {
	replies: Joi.boolean(),
	captionWarning: Joi.boolean(),
	autoplay: Joi.boolean(),
	captionEnabled: Joi.boolean(),
	automaticRedirect: Joi.boolean(),
	automaticRedirectThumbnail: Joi.string().allow(null, ''),
};

const settingsSchema = {
	settings: templateCampaignSettingsSchema,
};

const templateCampaignFieldsVideoPage = {
	...settingsSchema,
};

const templateCampaignFieldsWidget = {
	...templateWidgetFields,
	...settingsSchema,
};

// Validators with errors formatter
export async function templateFieldsValidator(fields: any) {
	try {
		await templateValidatorSchema.validateAsync(fields, {
			abortEarly: false,
		});

		return true;
	} catch (e: any) {
		let errors: string[] = [];
		if (e?.details) {
			e?.details?.forEach((e: any) => {
				errors = [...errors, e.message];
			});
		}
		throw errors;
	}
}

export async function templateCampaignFieldsValidator({
	fields,
	type,
}: {
	fields: any;
	type: TemplateCampaignFieldsValidatorType;
}) {
	const fieldsPerType = {
		widget: templateWidgetFields,
		'all-video-page': templateCampaignFieldsVideoPage,
		'all-widget': templateCampaignFieldsWidget,
	};

	const templateCampaignValidatorSchema = Joi.object({
		...(fieldsPerType[type] as any),
		overlay: Joi.string(),
	});

	try {
		await templateCampaignValidatorSchema.validateAsync(fields, {
			abortEarly: false,
		});

		return true;
	} catch (e: any) {
		let errors: string[] = [];
		if (e?.details) {
			e?.details.forEach((e: any) => {
				errors = [...errors, e.message];
			});
		}
		throw errors;
	}
}

// Template campaign fields validator

export const validateTemplateCampaignFields = async (
	fields: Partial<CampaignType>,
	errorsSetter: (errors: string[]) => void,
	type: TemplateCampaignFieldsValidatorType,
	callback?: () => void,
) => {
	try {
		await templateCampaignFieldsValidator({ fields, type });
		errorsSetter([]);
		if (callback) {
			callback();
		}

		return true;
	} catch (errors: any) {
		errorsSetter(errors);
		return false;
	}
};

export async function CTAFieldsValidator(fields: any) {
	const ctaType:
		| CTAListEnum.link
		| CTAListEnum.doubleLink
		| CTAListEnum.code
		| CTAListEnum.event
		| CTAListEnum.suggestedReply = fields?.type || CTAListEnum.link;

	const schemaToUse = CTASchemas[ctaType];

	try {
		if (schemaToUse) {
			await schemaToUse.validateAsync(fields, {
				abortEarly: false,
			});
		}

		return true;
	} catch (e: any) {
		let errors: { [key: string]: string } = {};

		if (e?.details) {
			errors = e?.details.reduce((acc: any, curr: any) => {
				if (curr.context.details) {
					curr.context.details.forEach((internalE: any) => {
						// Validate duplicates
						if (!Boolean(acc[internalE?.context?.key])) {
							acc = { ...acc, [internalE?.context?.key]: internalE.message };
						}
					});
				} else {
					// Validate duplicates
					if (!Boolean(acc[curr.context.key])) {
						acc = { ...acc, [curr.context.key]: curr.message };
					}
				}

				return acc;
			}, {});
		}

		throw errors;
	}
}

type getTemplatesFilterStructureType = {
	page: number;
	sort?: orderObject;
	searchQueryByName?: string;
	pageSize?: number;
	templateType?: videoViewerEnum;
};

export function getVideoPageTemplatesFilterStructure({
	page = 1,
	pageSize = 10,
	sort,
	searchQueryByName,
	templateType = videoViewerEnum.VIDEO_PAGE,
}: getTemplatesFilterStructureType) {
	const FilterStructure: FilterOptions = {
		filter: [
			{
				field: 'type',
				value: templateType,
			},
		],
		pagination: {
			page,
			pageSize,
		},
		populate: [],
		order: sort || undefined,
		search: searchQueryByName
			? {
					fields: ['name'],
					value: searchQueryByName,
				}
			: undefined,
	};

	return FilterStructure;
}

export const getPlaceholderVideoId = () => {
	const currentEnv = env.NODE_ENV || 'local';
	const isProd = ['production', 'prod'].includes(currentEnv);

	const isQa = ['qa'].includes(currentEnv);

	const isDev = ['development', 'dev'].includes(currentEnv);

	if (isProd) return `${placeholderVideoIds?.PROD}`;

	if (isQa) return `${placeholderVideoIds?.QA}`;

	if (isDev) return `${placeholderVideoIds?.DEV}`;

	return `${placeholderVideoIds?.LOCAL}`;
};

export const checkIsShowingAutomaticRedirect = (storedCTAFields: any) => {
	return (
		storedCTAFields?._id &&
		[CTATypeEnum.LINK, CTATypeEnum.DOUBLE_LINK, CTATypeEnum.EVENT].includes(
			storedCTAFields?.type,
		)
	);
};

export const getFetchOverlaysFilterStructure = (type = '') => {
	const paginationObject = {
		page: 1,
		pageSize: 3,
	};
	const searchObject =
		(type && {
			fields: ['tags'],
			value: type,
		}) ||
		undefined;

	const orderObject = { field: 'createdAt', type: orderTypes.descending };
	return {
		pagination: paginationObject,
		search: type ? searchObject : undefined,
		order: orderObject,
	};
};
