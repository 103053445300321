type Env = {
	CUBE_API_URL: string | undefined;
	VERSION: string | undefined;
	NODE_ENV: string | undefined;
	IS_LOCAL_VPAGE: string | undefined;
	PRID: string | undefined;
	WEB_RECORDING_URL: string | undefined;
	HOTGLUE_ENV_URL: string | undefined;
	HOTGLUE_API_KEY: string | undefined;
	API_URL: string | undefined;
	APP_URL: string | undefined;
	AUTH0_DOMAIN: string | undefined;
	AUTH0_CLIENT_ID: string | undefined;
	AUTH0_AUDIENCE: string | undefined;
	CLOUDINARY_CLOUD_NAME: string | undefined;
	CLOUDINARY_API_KEY: string | undefined;
	OLD_AUTH0_DOMAIN: string | undefined;
	OLD_AUTH0_CLIENT_ID: string | undefined;
	OLD_AUTH0_AUDIENCE: string | undefined;
	KEEN_PROJECT_ID: string | undefined;
	KEEN_READ_KEY: string | undefined;
	ALGOLIA_STORYTELLER_INDEX_NAME: string | undefined;
	ALGOLIA_VIDEO_INDEX_NAME: string | undefined;
	GOOGLE_FONTS_API_KEY: string | undefined;
	ENABLE_DATADOG: string | undefined;
	GROWTHBOOK_API_KEY: string | undefined;
	INTERCOM_APP_ID: string | undefined;
	SEGMENT_WRITE_KEY: string | undefined;
	BIRD_EATS_BUG_APP_ID: string | undefined;
	UNLAYER_PROJECT_ID: string | undefined;
	PUSHER_APP_KEY: string;
	PROPEL_AUTH_URL: string;
	POLOTNO_KEY: string;
	UNSPLASH_ACCESS_KEY: string;
	META_FB_APP_ID: string;
	META_FB_CONFIG_ID: string;
	META_FB_SOLUTION_ID: string;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const windowEnv: Env = window.env;
export const env = windowEnv || process.env;
