import styled from 'styled-components';

export const TranscriptBox = styled.div<{ hasCaptions: boolean }>`
	position: relative;
	border: 1.5px solid
		${({ theme, hasCaptions }) =>
			hasCaptions
				? theme.colors.system.border.medium
				: theme.colors.neutral.border.default};
	background: ${({ theme }) => theme.colors.system.background.system};
	border-radius: ${({ theme }) => theme.shapeRadius.regular};
	width: 100%;
	height: 100%;
	padding: 4px;
	padding-bottom: 0;

	&:hover {
		box-shadow: ${({ theme }) => theme.shadows.controls.hover};
	}

	&:focus,
	&:active {
		box-shadow: ${({ theme }) => theme.shadows.controls.selected};
		border: 1px solid ${({ theme }) => theme.colors.primary.border.default};
		outline: none;
	}
`;

export const TranscriptTextArea = styled.div`
	overflow: auto;
	height: 100%;
	padding: 12px;
`;

export const TranscriptCue = styled.span`
	margin-right: 5px;
	line-height: ${({ theme }) => theme.fonts.lineHeight.large};
	font-size: ${({ theme }) => theme.fonts.sizes.small};

	${({ confidence }: { confidence: number }) =>
		confidence <= 0.75 ? 'border-bottom:dashed red 2px;' : ''}
	:hover {
		//text-decoration:underline blue;
		border-bottom: 2px solid
			${({ theme }) => theme.colors.primary.border.default};
	}
	:hover,
	:focus {
		text-decoration: none;
	}
	:focus {
		outline: black dotted 0.1px;
		text-decoration: none;
	}
	:empty {
		border: 1px solid ${({ theme }) => theme.colors.error.border.default};
	}
	:empty:after {
		color: ${({ theme }) => theme.colors.error.border.default};
		content: ' Please input text in caption cue ';
	}
`;

export const State = styled.p`
	margin-top: 0;
	color: red;
`;
