import { videoStatusEnum, VideoStepEnum } from '@app/redux/video/constants';

import { VideoType } from '@app/types/modules/video';

export function isVideoPlayable(video: VideoType) {
	if (!video) return false;

	const isProcessing =
		video.status === 'Processing' &&
		video.muxStreamingUrl &&
		video.mediaVersion === 3;

	const isProcessed = video.status === 'Processed';

	const isStaticPlay = video.status === 'StaticPlay';

	return isProcessed || isProcessing || isStaticPlay;
}

export function getCaptionsStatus(video: VideoType) {
	if (video?.status === 'Processing' || video?.status === 'StaticPlay') {
		return 'Generating captions...';
	}
	return 'No transcript available.';
}

export function isTranscriptVisible(video: VideoType) {
	return (
		!!video?.captions?.length || video?.status === videoStatusEnum.processed
	);
}

export function getNoCaptionsText(video: VideoType) {
	return video &&
		(video.status === videoStatusEnum.processing ||
			(video.status === videoStatusEnum.staticPlay &&
				video.step === VideoStepEnum.analyzingAudio))
		? 'Generating captions...'
		: 'Automatic captioning did not detect any words in this video.';
}
