import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, ToastMessage } from '@app/constants';
import { fetchAction } from '../types';
import { FilterOptions } from '@app/types/index';
import {
	EditStoryOptionsTypes,
	CreateStoryOptionsTypes,
	SendStoryOptionsTypes,
} from './types/index';
import axios from '@global/axios';
import { CaptionType } from '@common/components/interaction/CaptionEditor/types';
import { StoriesType } from '@app/types/modules/stories';

const mainType = 'STORY';

export const filterStoriesTypes = createFetchTypes(
	`${mainType}_FILTER_STORIES`,
);

export const filterStoriesWithInfiniteTypes = createFetchTypes(
	`${mainType}_FILTER_STORIES_WITH_INFINITE`,
);

export const exportStoriesTypes = createFetchTypes(
	`${mainType}_EXPORT_STORIES`,
);

export const getApprovalsTypes = createFetchTypes(`${mainType}_GET_APPROVALS`);

export const editStoriesTypes = createFetchTypes(`${mainType}_EDIT_STORIES`);

export const createStoriesTypes = createFetchTypes(
	`${mainType}_CREATE_STORIES`,
);
export const sendStoriesTypes = createFetchTypes(`${mainType}_SEND_STORIES`);

export const approvalCountTypes = createFetchTypes(
	`${mainType}_GET_APPROVAL_COUNT`,
);
export const bulkApproveTypes = createFetchTypes(`${mainType}_BULK_APPROVE`);
export const bulkDeleteTypes = createFetchTypes(`${mainType}_BULK_DELETE`);
export const bulkRejectTypes = createFetchTypes(`${mainType}_BULK_REJECT`);
export const getSingleStoryTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_STORY`,
);
export const getStoriesCountTypes = createFetchTypes(
	`${mainType}_GET_STORIES_COUNT`,
);

// leaves
function exportStories(filterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify(filterOptions);

	return {
		type: exportStoriesTypes,
		callAPI: () =>
			axios({
				url: `${apiUrl}/stories/export${
					filterOptions ? `?filter=${filterString}` : ``
				}`,
				method: 'GET',
				responseType: 'blob',
			}),
		payload: {
			custom: 'exportStories',
		},
	};
}

//stays
function filterStories(
	FilterOptions: FilterOptions,
	infinite = false,
	custom?: string,
): fetchAction {
	const filterString = JSON.stringify(FilterOptions);

	return {
		type: infinite ? filterStoriesWithInfiniteTypes : filterStoriesTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/stories${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
		payload: {
			custom,
		},
	};
}

//stays
function getSingleStory(storyId: string) {
	return {
		type: getSingleStoryTypes,
		callAPI: () => axios.get(`${apiUrl}/stories/${storyId}`),
	};
}

//stays
// ### get approvals here , we can clean this and not pass an object, just pagination to it , as filters are fixed
function getApprovals(FilterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify({
		...FilterOptions,
		filter: [
			...(FilterOptions.filter ?? []),
			{ field: 'status', value: 'pendingApproval' },
		],
	});

	return {
		type: getApprovalsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/stories${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
	};
}

function editStories(EditOptions: EditStoryOptionsTypes): fetchAction {
	return {
		type: editStoriesTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/stories/bulk/edit`, {
				...EditOptions,
			}),
		payload: {
			custom: 'editStories',
			successMessage: ToastMessage.editedAssignedStory,
			errorMessage: ToastMessage.error,
		},
	};
}

function createStory(CreateOptions: CreateStoryOptionsTypes): fetchAction {
	return {
		type: createStoriesTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/stories`, {
				...CreateOptions,
			}),
		payload: {
			successMessage: ToastMessage.assignedStoryCreated,
			errorMessage: ToastMessage.errorAssigningStory,
		},
	};
}
function sendStory(SendOptions: SendStoryOptionsTypes): fetchAction {
	return {
		type: sendStoriesTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/stories`, {
				...SendOptions,
			}),
		payload: {
			successMessage: ToastMessage.storySent,
			errorMessage: ToastMessage.errorSendingStory,
		},
	};
}

function getApprovalCount() {
	return {
		type: approvalCountTypes,
		callAPI: () => axios.get(`${apiUrl}/stories/approvalCount`),
		payload: { custom: 'approvalCount' },
	};
}

function clearStoryError() {
	return {
		type: `${mainType}_CLEAR_ERROR`,
		payload: {},
	};
}

export const clearStoriesType = `story/CLEAR_STORIES`;

function clearStories() {
	return {
		type: clearStoriesType,
	};
}

export const clearApprovalDetailModalStoriesType = `story/CLEAR_APPROVAL_DETAIL_MODAL_STORIES`;

function clearApprovalDetailModalStories() {
	return {
		type: clearApprovalDetailModalStoriesType,
	};
}

export const clearResponseType = `story/CLEAR_RESPONSE`;
function clearResponse() {
	return {
		type: clearResponseType,
	};
}

function bulkApprove(stories: string[]) {
	return {
		type: bulkApproveTypes,
		callAPI: () => axios.patch(`${apiUrl}/stories/bulk/approval`, { stories }),
		payload: { custom: 'bulkApprove' },
	};
}

function bulkDelete(storiesId: string[]) {
	return {
		type: bulkDeleteTypes,
		callAPI: () =>
			axios.delete(`${apiUrl}/stories/bulk`, { data: { storiesId } }),
		payload: {
			custom: 'bulkDelete',
			successMessage: ToastMessage.success,
			errorMessage: ToastMessage.error,
		},
	};
}

function bulkReject(stories: string[], rejectionReason: string) {
	return {
		type: bulkRejectTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/stories/bulk/reject`, {
				stories,
				rejectionReason,
			}),
		payload: { custom: 'bulkReject' },
	};
}

export const updateApprovalVideoCaptionsTypes = `story/UPDATE_APPROVAL_VIDEO_CAPTIONS`;

function updateApprovalVideoCaptions(
	approvalVideoId: string,
	captions: CaptionType[],
) {
	return {
		type: updateApprovalVideoCaptionsTypes,
		payload: {
			approvalVideoId,
			captions,
		},
	};
}
export const updateApprovalVideoFieldTypes = `story/UPDATE_APPROVAL_VIDEO_FIELD`;

function updateApprovalVideoField(
	approvalId: string,
	field: string,
	value: any,
) {
	return {
		type: updateApprovalVideoFieldTypes,
		payload: {
			approvalId,
			field,
			value,
		},
	};
}

function getStoriesCount() {
	return {
		type: getStoriesCountTypes,
		callAPI: () => axios.get(`${apiUrl}/stories/storiesCount`),
		payload: { custom: 'getStoriesCount' },
	};
}

function updateStoryData(story: Partial<StoriesType>) {
	return {
		type: 'story/UPDATE_STORY_DATA',
		payload: {
			story,
		},
	};
}

export {
	clearStoryError,
	filterStories,
	editStories,
	createStory,
	getApprovals,
	sendStory,
	getApprovalCount,
	exportStories,
	clearStories,
	bulkApprove,
	bulkDelete,
	bulkReject,
	clearResponse,
	getSingleStory,
	updateApprovalVideoCaptions,
	updateApprovalVideoField,
	getStoriesCount,
	updateStoryData,
	clearApprovalDetailModalStories,
};
