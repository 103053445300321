import { AvailableMergeTags } from '@app/constants/mergeTags';
import { OptionsType } from '../types';

export function cleanTextWithSpaceRemoval(text: string): string {
	return text
		.replace(/&nbsp;/g, ' ')
		.replace(/&amp;/g, '&')
		.replace(/&gt;/g, '>')
		.replace(/&lt;/g, '<');
}

export function clearTagFormatFromEditorText(text: string) {
	return cleanTextWithSpaceRemoval(
		text
			.replace(new RegExp(/\{\{\{/gi), '')
			.replace(new RegExp(/\}\}\}/gi), ''),
		// .replace(new RegExp(/\{\{\{(.*)\}\}\}/))
	);
}

export function addCustomAttributesToMergeTagOptions(
	options: OptionsType[],
	workspace: any,
) {
	const customAttributesArray = workspace?.customAttributes || [];
	if (!customAttributesArray.length) {
		return options;
	}
	const customAttributesOptionsArray: { label: string; value: string }[] = [];
	customAttributesArray.length &&
		customAttributesArray.map((attribute: any) => {
			customAttributesOptionsArray.push({
				label: `${attribute.displayName}`,
				value: `contact.customAttributes.${attribute.name}`,
			});
		});

	return [...options, ...customAttributesOptionsArray];
}
export function replaceMergeToBraceFormat(
	text: string,
	workspace: any = [],
	mergeTagsToReplace?: { label: string; value: string }[],
) {
	let editedText = text || '';

	const customAttributes =
		workspace?.customAttributes?.map(
			(customAttribute: { displayName: string; name: string }) => {
				return {
					value: `contact.customAttributes.${customAttribute?.name}`,
					label: customAttribute?.displayName,
				};
			},
		) || [];

	const defaultMergeTags = [
		...AvailableMergeTags.emailSubjectTitle,
		...AvailableMergeTags.action,
		...customAttributes,
	];

	const mergeTags = !!mergeTagsToReplace?.length
		? [...mergeTagsToReplace, ...customAttributes]
		: defaultMergeTags;

	mergeTags.forEach(({ value, label }) => {
		editedText = editedText.replace(
			new RegExp(`{{{${value}}}}`, 'ig'),
			`[${label}]`,
		);
	});

	return editedText;
}

export function checkMergeTagsHasSpacingWarning(text: string) {
	const doubleMergeTagsRegex = /}}}{{{/;

	// Match chars before the merge tag, it matches any alphanumeric chars and special chard followed by '{{{'
	const prefixRegex = /[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{{{/;

	// Match chars after the merge tag, it matches '}}}' followed by any alphanumeric chars
	const suffixRegex = /}}}(?:[a-zA-Z0-9]|\.{1,}[a-zA-Z0-9])/;

	const doubleTagsMatches = text.match(doubleMergeTagsRegex);
	const charBeforeTagsMatches = text.match(prefixRegex);
	const charAfterTagsMatches = text.match(suffixRegex);

	return (
		!!doubleTagsMatches || !!charBeforeTagsMatches || !!charAfterTagsMatches
	);
}

export function checkHasSquareBracketMergeTags(text: string) {
	//Matches "{content]", "[content}" and "[content]"
	const squareBracketRegex = /\[.*?]|\{.*?]|\[.*?\}/g;

	const squareBracketMatches = text.match(squareBracketRegex);

	return !!squareBracketMatches;
}

export function checkHasCurlyBracesMergeTags(text: string) {
	// Regular expression to find all instances of curly brace groups
	const curlyBracesRegex = /\{+[^{}]+\}+/g;

	//We need to allow three curly braces, this is the format we actually store for merge tags
	const threeCurlyBracesRegex = /^\{\{\{[^{}]+\}\}\}$/;

	const curlyBracesMatches = text.match(curlyBracesRegex);

	if (!curlyBracesMatches) return false;

	for (const match of curlyBracesMatches) {
		if (!threeCurlyBracesRegex.test(match)) {
			return true;
		}
	}
	return false;
}
