import { GenericEntityState, ResponseAction } from '@app/redux/types';
import createFetchReducer from '@app/utils/createFetchReducer';
import {
	getAiBotByIdTypes,
	updateAiBotTypes,
	getAiBotConversationThreadTypes,
	getAiBotConversationTypes,
	getAiBotKnowledgeBaseDocsType,
	getAiBotQADocsApprovedType,
	getWorkspaceAiBotsTypes,
	deleteAiDocType,
	createNewAiDocType,
	updateAiDocType,
	getAiBotQADocsToReviewType,
	getAiBotStatsTypes,
	createAiChatBotTypes,
	getAiBotTopicsTypes,
	getAiBotUnansweredQuestionsType,
	getAiBotUrlSourcesType,
	getAiBotFileSourcesType,
	updateAiBotTopicType,
	bulkUseCachedQuestionSuggestionsType,
	removeAiBotUnansweredType,
	getAiBotQADocsUpdateSuggestionsType,
	bulkDismissCachedQuestionSuggestionsType,
} from '@app/redux/chatbot/actions';
import { createSlice } from '@reduxjs/toolkit';
import { aiDoc, aiTopic } from '@app/containers/Chatbot/types';
import { extractURL, topicTitleCase } from '@app/containers/Chatbot/util';

const initialState: GenericEntityState = {
	loading: false,
	error: {},

	response: {
		status: null,
		message: null,
	},

	aiBotsList: [],

	currentAiBotInDetail: null,

	aiBotKnowledgeBaseDocs: {
		docs: [],
		page: 1,
		count: 0,
		pageSize: 10,
		totalPages: 0,
	},

	aiBotQADocsApproved: {
		docs: [],
		page: 1,
		count: 0,
		pageSize: 10,
		totalPages: 0,
	},
	aiBotQADocsUpdateSuggestions: {
		docs: [],
		page: 1,
		count: 0,
		pageSize: 10,
		sourcesPreview: [],
		totalPages: 0,
	},
	aiBotQADocsToReview: {
		docs: [],
		page: 1,
		count: 0,
		pageSize: 10,
		totalPages: 0,
	},

	aiBotConversations: {
		conversations: [],
		page: 1,
		count: 0,
		pageSize: 10,
		totalPages: 0,
	},
	aiBotConversationThread: null,
	aiBotStats: {},
	aiBotTopics: {
		topics: [],
		subTopics: [],
		page: 1,
		count: 0,
		pageSize: 25,
		totalPages: 0,
	},
	aiBotUnansweredQuestions: {
		aiMessages: [],
		page: 1,
		count: 0,
		pageSize: 25,
		totalPages: 0,
	},
	aiBotSourceUrls: {
		urls: [],
		page: 1,
		count: 0,
		pageSize: 25,
		totalPages: 0,
	},
	aiBotSourceFiles: {
		files: [],
		page: 1,
		count: 0,
		pageSize: 25,
		totalPages: 0,
	},
	custom: {
		deleteAiDocLoading: false,
		createNewAiDocLoading: false,
		updateAiDocLoading: false,
		createNewAiDocSuccess: false,
		getWorkspaceAiBotsLoading: false,
		getAiBotByIdLoading: false,
		getAiBotConversationThreadLoading: false,
		updateAiBotLoading: false,
		createAiChatBotLoading: false,
		getAiBotQADocsToReviewLoading: false,
		getAiBotQADocsApprovedLoading: false,
		getAiBotQADocsUpdateSuggestionsLoading: false,
		getAiBotUnansweredQuestionsLoading: false,
		updateAiBotTopicLoading: false,
		updateAiBotUnansweredLoading: false,
	},
};

export type ChatbotStateType = typeof initialState;

function getWorkspaceAiBotsMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const {
		response: { aiBots },
	} = action;

	state.aiBotsList = aiBots;
}

function getAiBotByIdMapper(state: ChatbotStateType, action: ResponseAction) {
	const {
		response: { aiBot },
	} = action;

	state.currentAiBotInDetail = aiBot;
}

function updateAiBotMapper(state: ChatbotStateType, action: ResponseAction) {
	const {
		response: { aiBot },
	} = action;

	//Update currentAiBotInDetail
	state.currentAiBotInDetail = aiBot;

	//Update aiBot in aiBotsList
	state.aiBotsList = state.aiBotsList.map((item: { id: string }) => {
		if (item.id === aiBot.id) {
			return aiBot;
		}

		return item;
	});
}

function getAiBotConversationMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const {
		response: { aiConversations, page, count, pageSize, totalPages },
	} = action;

	if (page === 1) {
		state.aiBotConversations.conversations = aiConversations;
	} else {
		state.aiBotConversations.conversations = [
			...state.aiBotConversations.conversations,
			...aiConversations,
		];
	}
	state.aiBotConversations.page = page;
	state.aiBotConversations.count = count;
	state.aiBotConversations.pageSize = pageSize;
	state.aiBotConversations.totalPages = totalPages;
}
function getAiBotConversationThreadMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const {
		response: { aiConversation },
	} = action;

	state.aiBotConversationThread = aiConversation;
}

function getAiBotKnowledgeBaseDocsMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const {
		response: { aiDocs, totalPages },
	} = action;

	state.aiBotKnowledgeBaseDocs.docs = aiDocs;
	state.aiBotKnowledgeBaseDocs.totalPages = totalPages;
}

function getAiBotQADocsApprovedMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const {
		response: { aiDocs, count, pageSize, page, totalPages },
	} = action;

	if (page === 1) {
		state.aiBotQADocsApproved.docs = aiDocs;
	} else {
		state.aiBotQADocsApproved.docs = [
			...state.aiBotQADocsApproved.docs,
			...aiDocs,
		];
	}
	state.aiBotQADocsApproved.count = count;
	state.aiBotQADocsApproved.pageSize = pageSize;
	state.aiBotQADocsApproved.page = page;
	state.aiBotQADocsApproved.totalPages = totalPages;
}
function getAiBotQADocsToReviewMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const {
		response: { aiDocs, count, page, pageSize, totalPages },
	} = action;

	if (page === 1) {
		state.aiBotQADocsToReview.docs = aiDocs;
	} else {
		state.aiBotQADocsToReview.docs = [
			...state.aiBotQADocsToReview.docs,
			...aiDocs,
		];
	}
	state.aiBotQADocsToReview.count = count;
	state.aiBotQADocsToReview.pageSize = pageSize;
	state.aiBotQADocsToReview.page = page;
	state.aiBotQADocsToReview.totalPages = totalPages;
}

function getQaDocUpdateSuggestionSourcePreview(docs: any) {
	if (!docs || docs.length === 0) {
		return [];
	}

	return docs
		.map((doc: any) =>
			doc.sources.map((source: string) => extractURL(source, false)),
		)
		.flat()
		.filter((source: string) => source !== '') // remove empty strings
		.filter(
			(value: string, index: number, self: string[]) =>
				self.indexOf(value) === index,
		); // remove duplicates
}

function getAiBotQADocsUpdateSuggestionsMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const {
		response: { aiDocs, count, pageSize, page, totalPages },
	} = action;

	if (page === 1) {
		state.aiBotQADocsUpdateSuggestions.docs = aiDocs;
	} else {
		state.aiBotQADocsUpdateSuggestions.docs = [
			...state.aiBotQADocsUpdateSuggestions.docs,
			...aiDocs,
		];
	}

	if (
		state.aiBotQADocsUpdateSuggestions.sourcesPreview.length === 0 &&
		aiDocs
	) {
		state.aiBotQADocsUpdateSuggestions.sourcesPreview =
			getQaDocUpdateSuggestionSourcePreview(aiDocs);
	}

	state.aiBotQaDocsUpdateSuggestionsSourcesPreview =
		state.aiBotQaDocsUpdateSuggestions;

	state.aiBotQADocsUpdateSuggestions.count = count;
	state.aiBotQADocsUpdateSuggestions.pageSize = pageSize;
	state.aiBotQADocsUpdateSuggestions.page = page;
	state.aiBotQADocsUpdateSuggestions.totalPages = totalPages;
}

function createNewAiDocMapper(state: ChatbotStateType, action: ResponseAction) {
	const {
		response: { aiDoc },
		payload: { type, selectedTopicId },
	} = action;

	if (type === 'webSiteAIDoc' || type === 'fileAIDoc') {
		state.aiBotKnowledgeBaseDocs.docs.unshift(aiDoc);
	} else if (type === 'qaAIDoc') {
		if (!selectedTopicId || aiDoc.topics?.includes(selectedTopicId)) {
			state.aiBotQADocsApproved.docs.unshift(aiDoc);
			state.aiBotQADocsApproved.count = state.aiBotQADocsApproved.count + 1;
		}
	}
}

function updateAiDocMapper(state: ChatbotStateType, action: ResponseAction) {
	const {
		response: { aiDoc },
		payload: { type, userAction },
	} = action;
	if (type === 'webSiteAIDoc' || type === 'fileAIDoc') {
		state.aiBotKnowledgeBaseDocs.docs = state.aiBotKnowledgeBaseDocs.docs.map(
			(item: { id: string }) => {
				if (item.id === aiDoc.id) {
					return aiDoc;
				}
				return item;
			},
		);
	} else if (type === 'qaAIDoc') {
		if (userAction === 'saveInReview') {
			//just update the doc in the review state
			state.aiBotQADocsToReview.docs = state.aiBotQADocsToReview.docs.map(
				(item: { id: string }) => {
					if (item.id === aiDoc.id) {
						return aiDoc;
					}
					return item;
				},
			);
		} else if (userAction === 'approved') {
			// remove the iteam from aiBotQADocsToReview
			state.aiBotQADocsToReview.docs = state.aiBotQADocsToReview.docs.filter(
				(item: { id: string }) => item.id !== aiDoc.id,
			);
			// descrease the count of aiBotQADocsToReview
			state.aiBotQADocsToReview.count = state.aiBotQADocsToReview.count - 1;
			//increase the count of aiBotQADocsApproved
			state.aiBotQADocsApproved.count = state.aiBotQADocsApproved.count + 1;
		} else if (userAction === 'update') {
			// update the iteam from aiBotQADocsApproved
			state.aiBotQADocsApproved.docs = state.aiBotQADocsApproved.docs.map(
				(item: { id: string }) => {
					if (item.id === aiDoc.id) {
						return aiDoc;
					}
					return item;
				},
			);
			//if question is present in reviewd state
			state.aiBotQADocsToReview.docs.map((item: { id: string }) => {
				if (item.id === aiDoc.id) {
					// descrease the count of aiBotQADocsToReview
					state.aiBotQADocsToReview.count = state.aiBotQADocsToReview.count - 1;
					//increase the count of aiBotQADocsApproved
					state.aiBotQADocsApproved.count = state.aiBotQADocsApproved.count + 1;
					// remove the iteam from aiBotQADocsToReview
					state.aiBotQADocsToReview.docs =
						state.aiBotQADocsToReview.docs.filter(
							(item: { id: string }) => item.id !== aiDoc.id,
						);
				}
			});
		}
	}
}

function deleteAiDocMapper(state: ChatbotStateType, action: ResponseAction) {
	const {
		payload: { aiDocId, type, userAction },
	} = action;
	if (type === 'webSiteAIDoc' || type === 'fileAIDoc') {
		state.aiBotKnowledgeBaseDocs.docs =
			state.aiBotKnowledgeBaseDocs.docs.filter(
				(item: { id: string }) => item.id !== aiDocId,
			);
	} else if (type === 'qaAIDoc') {
		if (userAction === 'dismiss') {
			// remove the iteam from aiBotQADocsToReview
			state.aiBotQADocsToReview.docs = state.aiBotQADocsToReview.docs.filter(
				(item: { id: string }) => item.id !== aiDocId,
			);
			// descrease the count of aiBotQADocsToReview
			state.aiBotQADocsToReview.count = state.aiBotQADocsToReview.count - 1;
		} else if (userAction === 'delete') {
			// remove the iteam from aiBotQADocsApproved
			state.aiBotQADocsApproved.docs = state.aiBotQADocsApproved.docs.filter(
				(item: { id: string }) => item.id !== aiDocId,
			);
			// descrease the count of aiBotQADocsApproved
			state.aiBotQADocsApproved.count = state.aiBotQADocsApproved.count - 1;
		}
	}
}

function getAiBotStatsMapper(state: ChatbotStateType, action: ResponseAction) {
	const {
		response: { stats },
	} = action;

	state.aiBotStats = stats;
}
function createAiChatBotMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const {
		response: { aiBot },
	} = action;

	state.aiBotsList = [...state.aiBotsList, aiBot];
}

function getAiBotTopicsMapper(state: ChatbotStateType, action: ResponseAction) {
	const {
		response: { count, page, pageSize, topics, totalPages },
	} = action;

	// all topic names get converted to title case
	state.aiBotTopics.topics = topics
		.filter((topic: aiTopic) => !topic.parentTopic)
		.map(({ name, ...rest }: aiTopic) => ({
			name: topicTitleCase(name),
			...rest,
		}));
	state.aiBotTopics.subTopics = topics
		.filter((topic: aiTopic) => !!topic.parentTopic)
		.map(({ name, ...rest }: aiTopic) => ({
			name: topicTitleCase(name),
			...rest,
		}));
	state.aiBotTopics.count = count;
	state.aiBotTopics.page = page;
	state.aiBotTopics.pageSize = pageSize;
	state.aiBotTopics.totalPages = totalPages;
}

function getAiBotUnansweredQuestionsMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const {
		response: { aiMessages, count, page, pageSize, totalPages },
	} = action;

	if (page === 1) {
		state.aiBotUnansweredQuestions.aiMessages = aiMessages;
	} else {
		state.aiBotUnansweredQuestions.aiMessages = [
			...state.aiBotUnansweredQuestions.aiMessages,
			...aiMessages,
		];
	}

	state.aiBotUnansweredQuestions.count = count;
	state.aiBotUnansweredQuestions.page = page;
	state.aiBotUnansweredQuestions.pageSize = pageSize;
	state.aiBotUnansweredQuestions.totalPages = totalPages;
}

type SourceUrlType = {
	url: string;
	urlDepth: number;
};

function getAiBotUrlSourcesMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const {
		response: { urls, count, page, pageSize, totalPages },
	} = action;

	state.aiBotSourceUrls.urls = urls?.map((url: SourceUrlType) => url.url);
	state.aiBotSourceUrls.count = count;
	state.aiBotSourceUrls.page = page;
	state.aiBotSourceUrls.pageSize = pageSize;
	state.aiBotSourceUrls.totalPages = totalPages;
}

function bulkUseCachedQuestionSuggestionsMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const aiDocIds = action.payload.aiDocIds;
	// go through the action params and update the state
	//if the doc has .isSuggestedToDelete then remove it from the state
	//if the doc has .suggestedAnswer then update the state with the new answer
	for (let i = 0; i < aiDocIds.length; i++) {
		const docId = aiDocIds[i];
		const docFromApprovedState = state.aiBotQADocsApproved.docs.find(
			(item: { id: string }) => item.id === docId,
		);

		if (docFromApprovedState && docFromApprovedState.isSuggestedToDelete) {
			state.aiBotQADocsApproved.docs = state.aiBotQADocsApproved.docs.filter(
				(item: { id: string }) => item.id !== docId,
			);
		}

		if (docFromApprovedState && docFromApprovedState.suggestedUpdateAnswer) {
			state.aiBotQADocsApproved.docs = state.aiBotQADocsApproved.docs.map(
				(item: { id: string }) => {
					if (item.id === docId) {
						return {
							...item,
							answer: docFromApprovedState.suggestedUpdateAnswer,
							sources: docFromApprovedState.suggestedUpdateSources || [],
							suggestedUpdateAnswer: undefined,
							suggestedUpdateSources: undefined,
						};
					}
					return item;
				},
			);
		}
	}

	//remove the docs from the update suggestions state
	state.aiBotQADocsUpdateSuggestions.docs =
		state.aiBotQADocsUpdateSuggestions.docs.filter(
			(item: { id: string }) => !aiDocIds.includes(item.id),
		);
	//remove 1 from the count
	state.aiBotQADocsUpdateSuggestions.count =
		state.aiBotQADocsUpdateSuggestions.count - aiDocIds.length;
}

function bulkDismissCachedQuestionSuggestionsMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const aiDocIds = action.payload.aiDocIds;

	for (let i = 0; i < aiDocIds.length; i++) {
		const docId = aiDocIds[i];
		const docFromApprovedState = state.aiBotQADocsApproved.docs.find(
			(item: { id: string }) => item.id === docId,
		);

		if (docFromApprovedState) {
			state.aiBotQADocsApproved.docs = state.aiBotQADocsApproved.docs.map(
				(item: { id: string }) => {
					if (item.id === docId) {
						return {
							...item,
							suggestedUpdateAnswer: undefined,
							suggestedUpdateSources: undefined,
							isSuggestedToDelete: false,
						};
					}
					return item;
				},
			);
		}
	}
	//remove the docs from the update suggestions state
	state.aiBotQADocsUpdateSuggestions.docs =
		state.aiBotQADocsUpdateSuggestions.docs.filter(
			(item: { id: string }) => !aiDocIds.includes(item.id),
		);
	//remove 1 from the count
	state.aiBotQADocsUpdateSuggestions.count =
		state.aiBotQADocsUpdateSuggestions.count - aiDocIds.length;
}

function getAiBotFileSourcesMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const {
		response: { aiDocs, count, page, pageSize, totalPages },
	} = action;

	state.aiBotSourceFiles.files = aiDocs?.map((aiDoc: aiDoc) => aiDoc.fileName);
	state.aiBotSourceFiles.count = count;
	state.aiBotSourceFiles.page = page;
	state.aiBotSourceFiles.pageSize = pageSize;
	state.aiBotSourceFiles.totalPages = totalPages;
}

function removeAiBotUnansweredMapper(
	state: ChatbotStateType,
	action: ResponseAction,
) {
	const {
		payload: { aiMessageId },
	} = action;

	state.aiBotUnansweredQuestions.aiMessages =
		state.aiBotUnansweredQuestions.aiMessages.filter(
			(item: { id: string }) => item.id !== aiMessageId,
		);
	state.aiBotUnansweredQuestions.count =
		state.aiBotUnansweredQuestions.count - 1;
}

const chatbotSlice = createSlice({
	name: 'chatbot',
	initialState,
	reducers: {
		RESET_CHATBOT_LIST: (state) => {
			state.aiBotsList = [];
		},
	},
	extraReducers: {
		...createFetchReducer(getWorkspaceAiBotsTypes, getWorkspaceAiBotsMapper),
		...createFetchReducer(getAiBotByIdTypes, getAiBotByIdMapper),
		...createFetchReducer(updateAiBotTypes, updateAiBotMapper),
		...createFetchReducer(
			getAiBotConversationTypes,
			getAiBotConversationMapper,
		),
		...createFetchReducer(
			getAiBotConversationThreadTypes,
			getAiBotConversationThreadMapper,
		),
		...createFetchReducer(
			getAiBotKnowledgeBaseDocsType,
			getAiBotKnowledgeBaseDocsMapper,
		),
		...createFetchReducer(
			getAiBotQADocsApprovedType,
			getAiBotQADocsApprovedMapper,
		),
		...createFetchReducer(
			getAiBotQADocsToReviewType,
			getAiBotQADocsToReviewMapper,
		),
		...createFetchReducer(updateAiDocType, updateAiDocMapper),
		...createFetchReducer(deleteAiDocType, deleteAiDocMapper),
		...createFetchReducer(createNewAiDocType, createNewAiDocMapper),
		...createFetchReducer(getAiBotStatsTypes, getAiBotStatsMapper),
		...createFetchReducer(createAiChatBotTypes, createAiChatBotMapper),
		...createFetchReducer(getAiBotTopicsTypes, getAiBotTopicsMapper),
		...createFetchReducer(
			getAiBotUnansweredQuestionsType,
			getAiBotUnansweredQuestionsMapper,
		),
		...createFetchReducer(getAiBotUrlSourcesType, getAiBotUrlSourcesMapper),
		...createFetchReducer(getAiBotFileSourcesType, getAiBotFileSourcesMapper),
		...createFetchReducer(updateAiBotTopicType),
		...createFetchReducer(
			bulkUseCachedQuestionSuggestionsType,
			bulkUseCachedQuestionSuggestionsMapper,
		),
		...createFetchReducer(
			bulkDismissCachedQuestionSuggestionsType,
			bulkDismissCachedQuestionSuggestionsMapper,
		),
		...createFetchReducer(
			removeAiBotUnansweredType,
			removeAiBotUnansweredMapper,
		),
		...createFetchReducer(
			getAiBotQADocsUpdateSuggestionsType,
			getAiBotQADocsUpdateSuggestionsMapper,
		),
	},
});

export default chatbotSlice.reducer;
