import React from 'react';

import {
	AvatarList,
	Button,
	Checkbox,
	Text,
} from '@common/design-system/components/atoms';

import objectNestedKeys from '@app/utils/objectNestedKey';

import { TableItemType, TableMapperType } from '../../types';

import * as S from '../../Table.styles';
import {
	BadgeCell,
	BadgeFloatingMenuCell,
	CheckBoxCell,
	IconButtonCell,
	PinButtonCell,
	StatusIconCell,
	TextCell,
	UserDisplayCell,
	CircleIconCell,
	MultiBadgeCell,
	LinkButtonCell,
} from '../TableCells';

export type TableRowProps = {
	item: TableItemType;
	mappers: TableMapperType[];
	isChecked: boolean;
	onClickRow?: (item: any) => void;
	onCheckRow?: () => void;
	heightSize: number;
	firstColumnFixed?: boolean;
	lastColumnFixed?: boolean;
};

function TableRow({
	item,
	mappers,
	isChecked,
	onClickRow,
	onCheckRow,
	heightSize,
	firstColumnFixed,
	lastColumnFixed,
}: TableRowProps) {
	//TODO: Split into cell components
	const renderCell = (type: string, mapperField: TableMapperType) => {
		const buttonText =
			typeof mapperField.buttonText === 'function'
				? mapperField.buttonText(item)
				: mapperField.buttonText;

		switch (type) {
			case 'text':
				return <TextCell mapperField={mapperField} item={item} />;
			case 'user':
				return <UserDisplayCell mapperField={mapperField} item={item} />;
			case 'userList':
				return (
					<AvatarList displayAmount={3} avatars={item[mapperField.key] || []} />
				);
			case 'badge':
				return <BadgeCell mapperField={mapperField} item={item} />;
			case 'badgeFloatingMenu':
				return <BadgeFloatingMenuCell item={item} mapperField={mapperField} />;
			case 'multiBadge':
				return <MultiBadgeCell item={item} mapperField={mapperField} />;
			case 'statusIcon':
				return <StatusIconCell item={item} mapperField={mapperField} />;
			case 'circleIcons':
				return <CircleIconCell item={item} mapperField={mapperField} />;

			case 'linkButton':
				return <LinkButtonCell item={item} mapperField={mapperField} />;
			case 'button':
				return (
					mapperField.onButtonClick && (
						<Button
							variant="tertiary"
							{...mapperField?.buttonProps}
							onClick={() =>
								mapperField.onButtonClick && mapperField.onButtonClick(item)
							}
						>
							{buttonText}
						</Button>
					)
				);
			case 'buttonGroup':
				return (
					!!mapperField.buttonGroup?.length && (
						<S.ButtonGroupWrapper>
							{mapperField.buttonGroup.map((button, index) => (
								<Button
									key={index}
									variant="tertiary"
									{...button}
									onClick={() => button.onClick && button.onClick(item)}
								>
									{button.text}
								</Button>
							))}
						</S.ButtonGroupWrapper>
					)
				);
			case 'iconButton':
				return <IconButtonCell item={item} mapperField={mapperField} />;
			case 'checkbox':
				return <CheckBoxCell item={item} mapperField={mapperField} />;
			case 'pinButton':
				return <PinButtonCell item={item} mapperField={mapperField} />;
			default:
				return <Text>{objectNestedKeys(item, mapperField.key)}</Text>;
		}
	};

	const multiRenderCell = (
		mapperField: TableMapperType,
		mapperIndex?: number,
	) => {
		const CellCheckBox = () => {
			return (
				<>
					{!!onCheckRow && (
						<S.CheckboxTableCell onClick={(e) => e.stopPropagation()}>
							<Checkbox
								ariaLabel="Select row"
								checked={isChecked}
								onChange={() => onCheckRow()}
							/>
						</S.CheckboxTableCell>
					)}
				</>
			);
		};

		const typeData =
			typeof mapperField.type === 'function'
				? mapperField.type(item)
				: mapperField.type;

		const multiCellSettings =
			typeof mapperField.multiCellSettings === 'function'
				? mapperField.multiCellSettings(item)
				: mapperField.multiCellSettings;

		const multiCellSettingsClasses = {
			spaceBetweenCells: 'multi-cell-space-between',
			columnMode: 'multi-cell-column-mode flex-column align-items-start',
		};

		const renderNestedCell = (nestedType: any, nestedIndex: number) => {
			if (Array.isArray(nestedType)) {
				return (
					<div key={nestedIndex} className="d-flex align-items-center gap-2">
						{nestedType.map((innerType, innerIndex) => (
							<span key={innerIndex}>{renderCell(innerType, mapperField)}</span>
						))}
					</div>
				);
			}
			return (
				<span key={nestedIndex}>{renderCell(nestedType, mapperField)}</span>
			);
		};

		if (Array.isArray(typeData)) {
			return (
				<div
					className={`table-cell-inner-container multi-cell-inner-container ${
						multiCellSettings?.spaceBetweenCells
							? `${multiCellSettingsClasses.spaceBetweenCells}`
							: ''
					} ${
						multiCellSettings?.columnMode
							? `${multiCellSettingsClasses.columnMode}`
							: ''
					}`}
				>
					{typeData.map((type, cellTypeIndex) => (
						<span key={cellTypeIndex} className="d-flex align-items-center">
							<>
								{mapperIndex === 0 && cellTypeIndex === 0 && (
									<>{!!onCheckRow && <CellCheckBox />}</>
								)}
								{renderNestedCell(type, cellTypeIndex)}
							</>
						</span>
					))}
				</div>
			);
		}

		return (
			<div className="table-cell-inner-container">
				{mapperIndex === 0 && <>{!!onCheckRow && <CellCheckBox />}</>}
				{renderCell(typeData, mapperField)}
			</div>
		);
	};

	const getIsMaxContentWidth = (mapperField: TableMapperType) => {
		// This is used to tell which cells should have max-content width
		const maxContentCells = [
			'user',
			'userList',
			'button',
			'buttonGroup',
			'text',
			'iconButton',
			'badge',
		];

		// A recursive function to handle nested arrays
		const checkType = (type: string | string[]): boolean => {
			if (Array.isArray(type)) {
				return type.some((nestedType) => checkType(nestedType)); // Recursively check nested arrays
			}
			return maxContentCells.includes(type); // Check if the type is in maxContentCells
		};

		const typeToCheck =
			typeof mapperField.type === 'function'
				? mapperField.type(item)
				: mapperField.type;

		return Array.isArray(typeToCheck)
			? typeToCheck.some((type) => checkType(type)) // Check all elements, including nested ones
			: maxContentCells.includes(typeToCheck);
	};

	return (
		<S.StyledTableRow
			onClick={onClickRow ? () => onClickRow(item) : undefined}
			heightSize={heightSize}
			firstColumnFixed={firstColumnFixed}
			lastColumnFixed={lastColumnFixed}
		>
			<>
				{mappers.map((mapperField: TableMapperType, index: number) => (
					<S.TableCell
						key={index}
						centered={mapperField.centered}
						heightSize={heightSize}
						cellWidth={mapperField.cellWidth}
						cellWrap={mapperField.cellWrap}
						maxContentWidth={getIsMaxContentWidth(mapperField)}
					>
						{multiRenderCell(mapperField, index)}
					</S.TableCell>
				))}
			</>
		</S.StyledTableRow>
	);
}

export default React.memo(TableRow);
