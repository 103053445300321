import createFetchTypes from '@app/utils/createFetchTypes';
import { fetchAction } from '../types';
import { Action } from '@reduxjs/toolkit';
import axios, { axiosForExternal } from '@global/axios';
// import axiosFromLibrary from 'axios';
import { apiUrl, ToastMessage } from '@app/constants';
import { CaptionSuggestion, FilterOptions } from '@app/types';
import { CueData } from '@common/components/interaction/CaptionEditor/types';
const mainType = 'video';

export const uploadVideoTypes = createFetchTypes(`${mainType}_UPLOAD`);

function uploadVideo(formdata: FormData): fetchAction {
	return {
		type: uploadVideoTypes,
		callAPI: () => axios.post(`${apiUrl}/video?inputlanguage=en-US`, formdata),
		payload: {
			custom: 'videoUpload',
		},
	};
}

export const filterVideoTypes = createFetchTypes(`${mainType}_FILTER_VIDEOS`);
export const filterVideoWithInfiniteTypes = createFetchTypes(
	`${mainType}_FILTER_VIDEOS_WITH_INFINITE`,
);

function filterVideos(
	filterOptions: FilterOptions,
	infinite = false,
): fetchAction {
	const filterString = JSON.stringify(filterOptions);
	return {
		type: infinite ? filterVideoWithInfiniteTypes : filterVideoTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/video${filterOptions ? `?filter=${filterString}` : ``}`,
			),
		payload: {
			errorMessage: ToastMessage.error,
		},
	};
}

export const downloadVideoTypes = createFetchTypes(
	`${mainType}_DOWNLOAD_VIDEOS`,
);

function downloadVideo(videoId: string) {
	return {
		type: downloadVideoTypes,
		callAPI: () => axios.get(`${apiUrl}/video/download/${videoId}`),
	};
}

export const archiveVideoTypes = createFetchTypes(`${mainType}_ARCHIVE_VIDEO`);

function archiveVideo(videoId: string) {
	return {
		type: archiveVideoTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/video/${videoId}`, {
				video: { deleted: true, _id: videoId },
			}),
	};
}

export const toggleHideCaptionTypes = createFetchTypes(
	`${mainType}_TOGGLE_CAPTIONS`,
);

function toggleHideCaptions(videoId: string, hideCaptions: boolean) {
	return {
		type: toggleHideCaptionTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/video/${videoId}`, {
				video: { hideCaptions, _id: videoId },
			}),
	};
}

export const favoriteVideoTypes = createFetchTypes(
	`${mainType}_FAVORITE_VIDEO`,
);

function favoriteVideo(videoId: string, isFavorite = false) {
	return {
		type: favoriteVideoTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/video/${videoId}`, {
				video: { favorite: isFavorite, _id: videoId },
			}),
		payload: {
			custom: 'favoriteVideo',
			successMessage: isFavorite
				? ToastMessage.favorite
				: ToastMessage.favoriteUpdated,
			errorMessage: ToastMessage.error,
		},
	};
}

export const toggleShowWidgetTypes = `${mainType}_TOGGLE_SHOW_WIDGET`;

function toggleShowWidget(): Action {
	return {
		type: toggleShowWidgetTypes,
	};
}

export const getStreamingUrlTypes = createFetchTypes(
	`${mainType}_GET_STREAMING_URL`,
);

function getStreamingUrl(
	videoId: string,
	azureId: string,
	proxy: boolean,
	location: string,
) {
	return {
		type: getStreamingUrlTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/video/getStreamingUrl?videoId=${azureId}&proxy=${proxy}&location=${location}`,
			),
		payload: { videoId },
	};
}

export const getSingleVideoTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_VIDEO`,
);

function getSingleVideo(videoId: string) {
	return {
		type: getSingleVideoTypes,
		callAPI: () => axios.get(`${apiUrl}/video/${videoId}`),
		payload: {
			custom: 'getVideo',
		},
	};
}

export const clearVideosType = 'video/CLEAR_VIDEOS';

function clearVideos() {
	return {
		type: clearVideosType,
	};
}

export const clearFoldersType = 'video/CLEAR_FOLDERS';

function clearFolders() {
	return {
		type: clearFoldersType,
	};
}

export const updateCaptionTypes = createFetchTypes(
	`${mainType}_UPDATE_CAPTIONS`,
);

function updateCaptions(
	videoId: string,
	captions: string,
	language: string,
	dataByCue: { [key: string]: CueData },
) {
	return {
		type: updateCaptionTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/video/captions/${videoId}`, {
				captions,
				language,
				dataByCue,
			}),
		payload: {
			custom: 'updateCaptions',
			successMessage: ToastMessage.captionsUpdated,
		},
	};
}

export const updateThumbnailTypes = createFetchTypes(
	`${mainType}_UPDATE_THUMBNAIL`,
);

function updateThumbnail(
	newThumbnailSecond: number,
	videoId: string,
	showToast = true,
) {
	return {
		type: updateThumbnailTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/video/thumbnail/${videoId}`, {
				newThumbnailSecond,
			}),
		payload: {
			custom: 'updateThumbnail',
			successMessage: showToast ? ToastMessage.thumbnailUpdated : undefined,
		},
	};
}

export const getParsedCaptionTypes = createFetchTypes(
	`${mainType}_GET_PARSED_CAPTION`,
);

function getParsedCaptions(url: string, videoId: string) {
	return {
		type: getParsedCaptionTypes,
		callAPI: () => axiosForExternal.get(url),
		payload: {
			custom: 'getParsedCaption',
			id: videoId,
		},
	};
}

export const createCaptionSuggestionTypes = createFetchTypes(
	`${mainType}_CREATE_CAPTION_SUGGESTION`,
);

function createSuggestion(suggestion: CaptionSuggestion) {
	return {
		type: createCaptionSuggestionTypes,
		callAPI: () => axios.post(`${apiUrl}/video/suggestion`, { suggestion }),
		payload: {
			custom: 'createSuggestion',
			successMessage: ToastMessage.suggestionCreated,
			errorMessage: ToastMessage.error,
		},
	};
}

export const deleteCaptionSuggestionTypes = createFetchTypes(
	`${mainType}_DELETE_CAPTION_SUGGESTION`,
);

function deleteSuggestion(suggestionId: string) {
	return {
		type: deleteCaptionSuggestionTypes,
		callAPI: () => axios.delete(`${apiUrl}/video/suggestion/${suggestionId}`),
		payload: {
			custom: 'deleteSuggestion',
			successMessage: ToastMessage.suggestionDeleted,
			errorMessage: ToastMessage.error,
		},
	};
}

export const getCaptionSuggestionTypes = createFetchTypes(
	`${mainType}_GET_CAPTION_SUGGESTION`,
);

function getSuggestions(filterOptions?: FilterOptions): fetchAction {
	const filterString = JSON.stringify(
		filterOptions || { search: '', pagination: { page: 1, pageSize: 100 } },
	);
	return {
		type: getCaptionSuggestionTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/video/suggestion${
					filterString ? `?filter=${filterString}` : ``
				}`,
			),
		payload: {
			custom: 'getSuggestion',
		},
	};
}

export const updateVideoTypes = createFetchTypes(`${mainType}_UPDATE_VIDEO`);

function updateVideo(video: any, captionObject?: any, showToast = true) {
	return {
		type: updateVideoTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/video/${video._id}`, {
				video,
				...(captionObject ? { captionObject } : {}),
			}),
		payload: {
			custom: 'updateVideo',
			successMessage: showToast ? ToastMessage.videoUpdated : undefined,
			errorMessage: ToastMessage.errorUpdatingVideo,
		},
	};
}

export const createFolderTypes = createFetchTypes(`${mainType}_CREATE_FOLDER`);

function createFolder(data: object): fetchAction {
	return {
		type: createFolderTypes,
		callAPI: () => axios.post(`${apiUrl}/folders`, data),
	};
}

export const getVideoFoldersTypes = createFetchTypes(
	`${mainType}_GET_VIDEO_FOLDERS`,
);

function getVideoFolders(filterOptions?: any): fetchAction {
	const filterString = JSON.stringify({
		search: {
			fields: ['name'],
			value: filterOptions.search,
		},
		pagination: {
			page: filterOptions.page || 1,
			pageSize: filterOptions.pageSize || 100,
		},
		order: {
			field: 'createdAt',
			type: 'desc',
		},
		populate: [
			{
				path: 'size',
				field: 'size',
			},
		],
	});
	return {
		type: getVideoFoldersTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/folders${filterString ? `?filter=${filterString}` : ``}`,
			),
		payload: {
			custom: 'getVideoFolders',
		},
	};
}

export const addVideoToFolderTypes = createFetchTypes(
	`${mainType}_ADD_VIDEO_To_FOLDER`,
);

function addVideoToFolder(data: object): fetchAction {
	return {
		type: addVideoToFolderTypes,
		callAPI: () => axios.post(`${apiUrl}/video/addToFolder`, data),
	};
}

export const deleteVideoTypes = createFetchTypes(`${mainType}_DELETE_VIDEO`);

function deleteVideo(videoId: string | null): fetchAction {
	return {
		type: deleteVideoTypes,
		callAPI: () => axios.delete(`${apiUrl}/video/${videoId}`),
		payload: {
			custom: 'deleteVideo',
			id: videoId,
		},
	};
}

export const getFolderTypes = createFetchTypes(`${mainType}_GET_FOLDER`);

function getFolder(folderId?: string): fetchAction {
	const filterString = JSON.stringify({
		search: {
			fields: ['_id'],
			value: folderId,
		},
	});
	return {
		type: getFolderTypes,
		callAPI: () => axios.get(`${apiUrl}/folders?filter=${filterString}`),
		payload: {
			custom: 'getOneFolder',
		},
	};
}

export const editFolderTypes = createFetchTypes(`${mainType}_EDIT_FOLDER`);

function editFolder(data: { folderId: string; name: string }): fetchAction {
	return {
		type: editFolderTypes,
		callAPI: () => axios.patch(`${apiUrl}/folders/${data.folderId}`, data),
		payload: {
			custom: 'editFolder',
			successMessage: ToastMessage.editFolderSuccess,
		},
	};
}

export const deleteFolderTypes = createFetchTypes(`${mainType}_DELETE_FOLDER`);

function deleteFolder(folderId: string): fetchAction {
	return {
		type: deleteFolderTypes,
		callAPI: () => axios.delete(`${apiUrl}/folders/${folderId}`),
		payload: {
			custom: 'deleteFolder',
			id: folderId,
		},
	};
}

export const removeVideoFromFolderTypes = createFetchTypes(
	`${mainType}_REMOVE_VIDEO_FROM_FOLDER`,
);

function removeVideoFromFolder(data: object): fetchAction {
	return {
		type: removeVideoFromFolderTypes,
		callAPI: () => axios.post(`${apiUrl}/video/removeFromFolder`, data),
		payload: {
			custom: 'removeVideoFromFolder',
			videoId: data,
		},
	};
}

export {
	filterVideos,
	uploadVideo,
	toggleShowWidget,
	getStreamingUrl,
	clearVideos,
	getSingleVideo,
	downloadVideo,
	archiveVideo,
	favoriteVideo,
	updateCaptions,
	updateThumbnail,
	getParsedCaptions,
	toggleHideCaptions,
	createSuggestion,
	getSuggestions,
	deleteSuggestion,
	updateVideo,
	createFolder,
	getVideoFolders,
	addVideoToFolder,
	deleteVideo,
	getFolder,
	editFolder,
	deleteFolder,
	removeVideoFromFolder,
	clearFolders,
};
