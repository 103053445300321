import { handlePlural } from '@app/utils/handlePlural';

import {
	PersonalizationEnum,
	CreationMethod,
} from '../../../containers/CampaignCreation/constants';

import { PersonalizationEnum as PersonalizationEnumV2 } from '../../../containers/CampaignCreationV2/constants';

import { OptionsType } from '@app/types';
import { verifiedStorytellerType } from '@app/containers/CampaignCreation/containers/Summary/types';
import {
	formatDateMonthNominalDay,
	formatDateMonthNominalDayAndTime,
	formatTimeAMPM,
} from '@app/utils/formatDate';
import { CampaignType } from '@app/redux/campaign/types';
import { AudienceSyncStatusEnum } from '@app/constants/modules/audience';
import {
	AudienceHandlingTypeEnum,
	descriptionPreTexts,
	DuplicateCampaignConfirmationModalText,
} from '@app/constants/modules/campaign';
import { getCampaignDisplayType } from '@app/containers/CampaignsV2/utils';

export function checkIsCollectVideos(creationMethod: string): boolean {
	return (
		creationMethod === CreationMethod.collectVideos ||
		creationMethod === PersonalizationEnum.collectVideosSkipTemplates
	);
}

export function checkIsOneToOne(
	campaign?: Partial<CampaignType>,
	creationMethod?: string,
	personalization?: string,
) {
	const isCollectVideos = checkIsCollectVideos(
		campaign?.creationMethod || creationMethod || '',
	);

	if (isCollectVideos) return false;

	return (
		campaign?.personalization === PersonalizationEnum.oneToOne ||
		personalization === PersonalizationEnum.oneToOne
	);
}

export const checkIsOneToMany = (personalization: string): boolean => {
	return personalization === PersonalizationEnum.oneToAll;
};

export const checkIsOneToWorld = (personalization: string): boolean => {
	return personalization === PersonalizationEnum.oneToWorld;
};

export function checkIsOneToManyAssignNewVideo(campaign: CampaignType) {
	return (
		campaign?.personalization === PersonalizationEnum.oneToAll &&
		campaign?.creationMethod === CreationMethod.createToDos
	);
}

export function checkIsOneToManyVideoFromLibrary(
	campaign: Partial<CampaignType>,
) {
	return (
		campaign?.personalization === PersonalizationEnum.oneToAll &&
		campaign?.creationMethod === CreationMethod.videoFromLibrary
	);
}

export function checkIsOneToWorldVideoFromLibrary(
	campaign: Partial<CampaignType>,
) {
	return (
		campaign?.personalization === PersonalizationEnum.oneToWorld &&
		campaign?.creationMethod === CreationMethod.videoFromLibrary
	);
}

export function checkIsCollectVideosPersonalization(
	personalization: string,
): boolean {
	return (
		personalization === PersonalizationEnum.collectVideos ||
		personalization === PersonalizationEnum.collectVideosSkipTemplates
	);
}

export function checkIsReels(personalization: string): boolean {
	return personalization === PersonalizationEnumV2.reels;
}

export function checkIsReelsOnlyAssignments(campaign: CampaignType): boolean {
	const isReels = campaign.personalization === PersonalizationEnumV2.reels;
	const hasNoCompletedStories =
		!campaign?.completedStories || !campaign?.completedStories?.length;

	const hasAssignedStories =
		campaign?.assignedStories && campaign?.assignedStories?.length;

	return isReels && hasNoCompletedStories && hasAssignedStories ? true : false;
}

export function checkIsReelsOnlyVFL(campaign: CampaignType): boolean {
	const isReels = campaign.personalization === PersonalizationEnumV2.reels;
	const hasNoAssignedStories =
		!campaign?.assignedStories || !campaign?.assignedStories?.length;

	const hasCompletedStories =
		campaign?.completedStories && campaign?.completedStories?.length;

	return isReels && hasNoAssignedStories && hasCompletedStories ? true : false;
}

export function checkIsReelsWithAssignments(campaign: CampaignType): boolean {
	const isReels = campaign.personalization === PersonalizationEnumV2.reels;
	const hasAssignedStories =
		campaign?.assignedStories && campaign?.assignedStories?.length;

	return isReels && hasAssignedStories ? true : false;
}

export function checkIsReelsWithVFL(campaign: CampaignType): boolean {
	const isReels = campaign.personalization === PersonalizationEnumV2.reels;
	const hasCompletedStories =
		campaign?.completedStories && campaign?.completedStories?.length;

	return isReels && hasCompletedStories ? true : false;
}

export function checkIsSMS(personalization: string): boolean {
	return personalization === PersonalizationEnum.sms;
}

export function checkIsPost(personalization: string): boolean {
	return personalization === PersonalizationEnumV2.post;
}

export function checkIsScanToWatch(campaign: CampaignType) {
	return (
		campaign?.personalization === PersonalizationEnumV2.post &&
		campaign?.creationMethod === CreationMethod.videoFromLibrary
	);
}

export function checkIsPostcard(campaign: CampaignType) {
	return (
		campaign?.personalization === PersonalizationEnumV2.post &&
		campaign?.creationMethod === CreationMethod.noVideo
	);
}

export function checkIsWhatsapp(
	whatsappEnabled?: boolean,
	whatsappTemplateId?: string,
	personalization?: string,
): boolean {
	if (
		whatsappEnabled &&
		whatsappTemplateId &&
		personalization === PersonalizationEnum.sms
	) {
		return true;
	}

	return false;
}
export const getEmailTemplateDropdownOptions = (
	templates: any,
): OptionsType[] => {
	const dropdownOptions = [];

	if (templates) {
		const emailTemplateOptions = templates.map((template: any) => ({
			label: template.name,
			value: template.id,
		}));

		dropdownOptions.push(...emailTemplateOptions);
	}

	return dropdownOptions;
};

// Text telling which emails are verified or not,
// that are going to be senders of the campaign
export const getSendingEmailText = ({
	defaultSendingAddress,
	storytellers,
	verifiedStorytellers,
	loading,
}: {
	defaultSendingAddress: string;
	storytellers: any[];
	verifiedStorytellers: verifiedStorytellerType[];
	loading: boolean;
}) => {
	if (loading) return 'Loading...';

	const verifiedStorytellersAmount = verifiedStorytellers?.length || 0;

	if (verifiedStorytellersAmount === 0) return defaultSendingAddress;

	const selectedStorytellersAmount = storytellers?.length || 0;

	const hasAllEmailsVerified =
		!!verifiedStorytellersAmount &&
		!!selectedStorytellersAmount &&
		verifiedStorytellersAmount === selectedStorytellersAmount;

	const verifiedSenderText = `${verifiedStorytellersAmount} verified ${handlePlural(
		'sender',
		verifiedStorytellersAmount,
	)}`;

	if (hasAllEmailsVerified) return verifiedSenderText;

	const notVerifiedAmount =
		selectedStorytellersAmount - verifiedStorytellersAmount;

	const notVerifiedSenderText = `${notVerifiedAmount} ${handlePlural(
		'sender',
		notVerifiedAmount,
	)} with default address`;

	if (notVerifiedAmount && verifiedStorytellersAmount)
		return `${notVerifiedSenderText}, ${verifiedSenderText}`;

	return defaultSendingAddress;
};

export function getCampaignScheduledDate({
	scheduleDate,
	timezone,
	hideTime,
}: {
	scheduleDate: any;
	timezone?: string;
	hideTime?: boolean;
}): string {
	if (!scheduleDate) return '';

	if (timezone) {
		const dateFormatted = formatDateMonthNominalDayAndTime(
			scheduleDate,
			timezone,
		);

		const dateText = (dateFormatted as string).replace(', ', ' at ');

		if (hideTime) {
			return dateText.split(' at ')[0];
		}
		return dateText;
	}

	const date = `${formatDateMonthNominalDay(scheduleDate)}`.slice(0, -2);
	const time = hideTime ? '' : `at ${formatTimeAMPM(scheduleDate)}`;

	return `${date} ${time}`;
}

export function checkIsCampaignSyncingContacts(campaign: CampaignType) {
	if (!campaign?._id) return false;

	const hasContacts = campaign.contacts?.length > 0;

	const hasSynced = !!campaign.lastSyncDate;

	const audienceIsSyncing =
		campaign?.audience?.syncStatus === AudienceSyncStatusEnum.INACTIVE;

	return !hasSynced && !hasContacts && !audienceIsSyncing;
}

export function checkIsCampaignAudienceSyncInactive(campaign: CampaignType) {
	if (!campaign?._id) return false;
	const hasContacts = campaign.contacts?.length > 0;

	const hasSynced = !!campaign.lastSyncDate;

	const audienceIsSyncing =
		campaign?.audience?.syncStatus === AudienceSyncStatusEnum.INACTIVE;

	return !hasContacts && audienceIsSyncing && !hasSynced;
}

export function getLaunchedCampaignDescription(
	campaign: CampaignType,
	timezone?: string,
) {
	const scheduleDate = campaign?.scheduleDate;
	const isScheduledCampaign = !!scheduleDate;

	const isOneToOne = checkIsOneToOne(campaign);
	const isOneToManyVideoFromLibrary =
		checkIsOneToManyVideoFromLibrary(campaign);
	const isOneToManyAssignNewVideo = checkIsOneToManyAssignNewVideo(campaign);
	const isCollectVideosCampaign = checkIsCollectVideos(
		campaign?.creationMethod,
	);
	const isPost = checkIsPost(campaign?.personalization);
	const isReels = checkIsReels(campaign?.personalization);

	// Set preText based on campaign type and schedule date
	let preText = '';
	if (isCollectVideosCampaign) {
		preText = descriptionPreTexts.collectVideos;
	}
	if (isOneToOne) {
		preText = isScheduledCampaign
			? descriptionPreTexts.oneToOneScheduled
			: descriptionPreTexts.oneToOne;
	}
	if (isOneToManyVideoFromLibrary) {
		preText = isScheduledCampaign
			? descriptionPreTexts.oneToManyVideoFromLibraryScheduled
			: descriptionPreTexts.oneToManyVideoFromLibrary;
	}
	if (isOneToManyAssignNewVideo) {
		preText = isScheduledCampaign
			? descriptionPreTexts.oneToManyAssignNewVideoScheduled
			: descriptionPreTexts.oneToManyAssignNewVideo;
	}
	if (isReels) {
		preText = isScheduledCampaign
			? descriptionPreTexts.reelsScheduled
			: descriptionPreTexts.reels;
	}
	if (isPost) {
		preText = descriptionPreTexts.post;
	}

	// Return preText if no schedule date
	if (!isScheduledCampaign) {
		return preText;
	}

	const hideTimeAndTimezone = isPost;
	// Return preText and schedule date text
	const timezoneText = timezone ? ` (${timezone?.replace('_', ' ')})` : '';
	return `${preText} ${getCampaignScheduledDate({ scheduleDate, timezone, hideTime: hideTimeAndTimezone })}${hideTimeAndTimezone ? '.' : `${timezoneText}.`}`;
}

export function getAudienceHandlingText(audienceHandlingType: string) {
	switch (audienceHandlingType) {
		case AudienceHandlingTypeEnum.ONE_TIME_SYNC:
			return 'One time sync';
		case AudienceHandlingTypeEnum.ADDITIVE:
			return 'Additive';
		case AudienceHandlingTypeEnum.MAPPED:
			return 'Synced';
		default:
			return null;
	}
}

export function getRecurringCampaignText(campaign: CampaignType) {
	const isOneToOne = checkIsOneToOne(campaign);
	const audienceHandlingType = campaign?.audienceHandlingType;
	switch (audienceHandlingType) {
		case AudienceHandlingTypeEnum.ONE_TIME_SYNC:
			return 'Off';
		case AudienceHandlingTypeEnum.ADDITIVE:
			if (isOneToOne) return 'On • Additive';
			return 'On';
		case AudienceHandlingTypeEnum.MAPPED:
			return 'On • Synced';
		default:
			return '';
	}
}

export function getDuplicateCampaignDescription(campaign: CampaignType) {
	const isPostCampaign = checkIsPost(campaign?.personalization);
	const campaignTypeName = getCampaignDisplayType(campaign);

	return isPostCampaign
		? DuplicateCampaignConfirmationModalText.DESCRIPTION_POST.replace(
				'[campaignType]',
				campaignTypeName,
			)
		: DuplicateCampaignConfirmationModalText.DESCRIPTION;
}
